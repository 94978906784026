/* @flow */
import { combineReducers } from 'redux';
import find from 'lodash/find';

import {
  ADD_UPLOAD_PHOTO,
  SELECT_UPLOAD_PHOTOS,
  TOGGLE_UPLOAD_PHOTO_SELECTION,
  CLEAR_UPLOAD_SELECTION,
  UPDATE_UPLOAD_PHOTOS,
  REMOVE_UPLOAD_PHOTOS,
  SET_UPLOAD_POSTING_STATUS,
  SET_UPLOAD_PREFILL_INFO,
  ADD_UPLOAD_FILE_ERROR,
  SET_UPLOAD_PROGRESS,
  RESET_UPLOAD,
  RESET_UPLOAD_ERRORS,
  SHOULD_ASK_FOR_NAVIGATION_CONFIRMATION,
} from '../constants/actionTypes';

const selectedPhotoIds = (
  state: $ReadOnlyArray<EyeEmAssetId> = [],
  action: ReduxAction<Action>
) => {
  switch (action.type) {
    case RESET_UPLOAD:
      return [];
    case SELECT_UPLOAD_PHOTOS:
      return state.concat(action.payload.photosToSelect);
    case TOGGLE_UPLOAD_PHOTO_SELECTION: {
      const index = state.indexOf(action.payload.uuid);
      if (index >= 0) {
        return [...state.slice(0, index), ...state.slice(index + 1)];
      }
      return state.concat(action.payload.uuid);
    }
    case REMOVE_UPLOAD_PHOTOS:
      return state.filter(
        (uuid) => action.payload.photosToRemove.indexOf(uuid) === -1
      );
    case CLEAR_UPLOAD_SELECTION:
      return [];
    case ADD_UPLOAD_PHOTO:
      return state.length === 0 ? [action.payload.uploadPhoto.uuid] : state;
    default:
      return state;
  }
};

const uploadPhoto = (state: UploadPhoto, action: ReduxAction<Action>) => {
  switch (action.type) {
    case UPDATE_UPLOAD_PHOTOS: {
      if (action.payload.photosToUpdate.indexOf(state.uuid) >= 0) {
        const newState = {
          ...state,
        };
        // if we are using the cdnFilename already lets trash the dataUrl and free up some memory
        if (newState.cdnFilename) {
          newState.dataUrl = undefined;
        }
        return {
          ...newState,
          ...action.payload.fields,
          height: state.height || action.payload.fields.height,
          width: state.width || action.payload.fields.width,
        };
      }
      return state;
    }
    default:
      return state;
  }
};

const uploadPhotos = (
  state: $ReadOnlyArray<UploadPhoto> = [],
  action: ReduxAction<Action>
) => {
  switch (action.type) {
    case RESET_UPLOAD:
      return [];
    case REMOVE_UPLOAD_PHOTOS:
      return state.filter(
        (uploadPhotoItem) =>
          action.payload.photosToRemove.indexOf(uploadPhotoItem.uuid) === -1
      );
    case ADD_UPLOAD_PHOTO:
      return state.concat(action.payload.uploadPhoto);
    default:
      return state.map((uploadPhotoItem) =>
        uploadPhoto(uploadPhotoItem, action)
      );
  }
};

const posting = (state: boolean = false, action: ReduxAction<Action>) => {
  switch (action.type) {
    case RESET_UPLOAD:
      return false;
    case SET_UPLOAD_POSTING_STATUS:
      return action.payload.posting;
    default:
      return state;
  }
};

const shouldAskForNavigationConfirmation = (
  state: boolean = false,
  action: ReduxAction<Action>
) => {
  switch (action.type) {
    case SHOULD_ASK_FOR_NAVIGATION_CONFIRMATION:
      return action.payload;
    default:
      return state;
  }
};

const uploadPrefillInfo = (
  state: { album: EyeEmAlbum, missionId: EyeEmMissionId } | false = false,
  action: ReduxAction<Action>
) => {
  switch (action.type) {
    case SET_UPLOAD_PREFILL_INFO:
      return action.payload;
    case RESET_UPLOAD:
      return false;
    default:
      return state;
  }
};

const progress = (state: number = -1, action: ReduxAction<Action>) => {
  switch (action.type) {
    case RESET_UPLOAD:
    case ADD_UPLOAD_PHOTO:
      return -1;
    case SET_UPLOAD_PROGRESS:
      return action.payload.progress;
    default:
      return state;
  }
};

const errorsDefaultState = {
  oversize: [],
  unsupported: [],
  exceeded: [],
  generalRequest: [],
};

const errors = (
  state: UploadErrors = errorsDefaultState,
  action: ReduxAction<Action>
) => {
  switch (action.type) {
    case RESET_UPLOAD:
      return errorsDefaultState;
    case RESET_UPLOAD_ERRORS:
      return errorsDefaultState;
    case ADD_UPLOAD_FILE_ERROR:
      return {
        ...state,
        [action.payload.type]: [
          ...state[action.payload.type],
          action.payload.fileName,
        ],
      };
    default:
      return state;
  }
};

export default combineReducers({
  selectedPhotoIds,
  uploadPhotos,
  posting,
  uploadPrefillInfo,
  progress,
  errors,
  shouldAskForNavigationConfirmation,
});

export function mapInitialDataToInitialState(initialData: NormalizedData) {
  return {
    shouldAskForNavigationConfirmation: false,
    selectedPhotoIds: [],
    uploadPhotos: [],
    posting: false,
    uploadPrefillInfo:
      (initialData.upload && initialData.upload.uploadPrefillInfo) || null,
    progress: -1,
    errors: errorsDefaultState,
  };
}

export const getShouldAskForNavigationConfirmation = (state: UploadState) =>
  state.upload.shouldAskForNavigationConfirmation;

export const getFilesWithoutKeywords = (state: UploadState) =>
  state.uploadPhotos.filter(
    (uploadPhotoItem) => !state.keywords[`${uploadPhotoItem.uuid}-0`]
  );
export const getFilesWithoutClosestCity = (state: UploadState) =>
  state.uploadPhotos.filter(
    (uploadPhotoItem) => uploadPhotoItem.location === undefined
  );
export const getFilesWithoutIptc = (state: UploadState) =>
  state.uploadPhotos.filter(
    (uploadPhotoItem) => uploadPhotoItem.iptc === undefined
  );

export const getUploadPhotos = (state: UploadState) => state.uploadPhotos;
export const getSelectedUploadPhotoIds = (state: UploadState) =>
  state.selectedPhotoIds;
export const getSelectedUploadPhotos = (state: UploadState) =>
  state.selectedPhotoIds.map((uuid) => find(state.uploadPhotos, { uuid }));

export const getIsUploadPosting = (state: UploadState) => state.posting;
export const getUploadPrefillInfo = (state: UploadState) =>
  state.uploadPrefillInfo;
export const getUploadProgress = (state: UploadState) => {
  // Dropzone updates progress
  if (state.progress >= 0) {
    return state.progress;
  }

  // If we don't have a progress specified, our progress is just
  // the ratio between successful and total photos
  let count = 0;
  state.uploadPhotos.forEach((photo) => {
    if (photo.status === 'success') {
      count += 1;
    }
  });
  return (count / state.uploadPhotos.length) * 100;
};
export const getHasUnpostedPhotos = (state: UploadState) =>
  state.uploadPhotos.length > 0;
export const getIsUploadQueueComplete = (state: UploadState) =>
  state.uploadPhotos.every((photo) => photo.status === 'success');
export const getUploadErrors = (state: UploadState) => state.errors;
