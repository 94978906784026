/* @flow */
export const DOM_IDS = {
  REACT_STAGE: 'stage',
  REACT_STAGE_NAVBAR: 'nav-stage',
  REACT_STAGE_SIDENAVBAR: 'sidenav-stage',
  REACT_STAGE_FOOTER: 'footer-stage',
  REACT_STAGE_PORTAL_SEARCH: 'stage-portal-search',
  REACT_STAGE_PORTAL_SEARCH_RENDER: 'component-react-portal',
};

export const NOTIFICATION_LIFETIME = 10000;

export const CART_THUMB_HEIGHT = '88';

export const dataPath = '/data';
export const clientPath = '/client';

export const LOGIN_ROUTE = '/login';
export const SIGNUP_ROUTES = [
  '/signup',
  '/enterprise/signup',
  '/signup/buyer',
  '/signup/creator',
];

export const SIGNUP_AND_LOGIN_ROUTES = [
  LOGIN_ROUTE,
  ...SIGNUP_ROUTES,
  '/password',
  '/confirm/:resetPasswordToken',
  '/enterprise/passwordReset',
  '/oauth/authorize',
];

// "verified" field on sellerData object
export const SELLER_EMAIL_NOT_VERIFIED = '0';
export const SELLER_EMAIL_VERIFIED = '1';

// GENDER FIELD IN RELEASES
export const GENDER_MALE = 'MALE';
export const GENDER_FEMALE = 'FEMALE';
export const GENDER_OTHER = 'OTHER';

// MODEL IDENTITY FIELD IN RELEASES
export const MODEL_IDENTITY_SELF = 'SELF';
export const MODEL_IDENTITY_MY_KID = 'MY_KID';
export const MODEL_IDENTITY_OTHER = 'OTHER';

// PHOTO MARKET STATUS
export const PHOTO_MARKET_STATUS_UNAVAIBLABLE = 0;
export const PHOTO_MARKET_STATUS_ON_HOLD = 1;
export const PHOTO_MARKET_STATUS_EDITORIAL = 2;
export const PHOTO_MARKET_STATUS_COMMERCIAL = 3;
// NOTE: value 4 doesn't exist in backend
export const PHOTO_MARKET_STATUS_REFUSED = 5;
export const PHOTO_MARKET_STATUS_UNKNOWN = 'UNKNOWN';

export const MARKET_PHOTO_MAX_REQUEST_WIDTH = 1280;

// releases filter
export const PHOTOS_NEED_RELEASES = 1;
export const PHOTOS_NEED_RELEASES_AND_REVIEW = 2;

// release types
export const RELEASE_TYPE_MODEL = 'MODEL';
export const RELEASE_TYPE_PROPERTY = 'PROPERTY';

export const MULTIRELEASE_MODALSTATE_SELECT = 'SELECT';
export const MULTIRELEASE_MODALSTATE_SELECT_EDIT = 'SELECT-EDIT';
export const MULTIRELEASE_MODALSTATE_SELECT_VIEW = 'SELECT-VIEW';
export const MULTIRELEASE_MODALSTATE_NAME = 'NAME';
export const MULTIRELEASE_MODALSTATE_CONFIRM = 'CONFIRM';

export const COLLECTIONS = {
  PREMIUM: 'premium',
  ESSENTIAL: 'essential',
  MIXED: 'mixed', // a synonym for "premium" + "essential"
};

// PRICE POINTS FOR LICENSES
export const AVAILABLE_PRICEPOINTS = ['regular', 'premium'];
export const PRICEPOINT_REGULAR = 'regular';
export const PRICEPOINT_PREMIUM = 'premium';
export const PRICEPOINT_TYPE_PRICE = 'price';
export const PRICEPOINT_TYPE_PACK = 'pack';

// LICENSE TYPES (legacy)
export const LICENSE_HI_RES: '0' = '0';
export const DEPRECATED_LICENSE_EDITORIAL: '3' = '3';
export const LICENSE_SOCIAL: '4' = '4';
export const LICENSE_WEB: '5' = '5';
export const LICENSE_FULL: '6' = '6';
export const LICENSE_STANDARD: '7' = '7';
export const LICENSE_EXTENDED: '8' = '8';

// LICENSE IDS
export const LICENSEID_HI_RES: 0 = 0;
export const LICENSEID_SOCIAL: 1 = 1;
export const LICENSEID_WEB: 2 = 2;
export const LICENSEID_FULL: 3 = 3;
export const LICENSEID_EDITORIAL: 4 = 4;
export const LICENSEID_STANDARD: 7 = 7;
export const LICENSEID_EXTENDED: 8 = 8;
export const LICENSEID_BASIC: 20 = 20;
export const LICENSEID_CUSTOM: 21 = 21;

export const LICENSEID_DEFAULT: number = LICENSEID_BASIC;

// ADYEN RESULT CODES
export const ADYEN_RESULT_CODES = {
  RECEIVED: 'received',
  AUTHORIZED: 'authorised',
  REFUSED: 'refused',
};

export const ADYEN_STATUS_SUCCESS = 'SUCCESS';

// PHOTO REVIEW STATUS
export const PHOTO_REVIEW_STATUS_PENDING = 0;
export const PHOTO_REVIEW_STATUS_ESCALATED = 1;
export const PHOTO_REVIEW_STATUS_REVIEWED = 2;

// PHOTO PARTNER STATUS
export const PHOTO_PARTNER_STATUS_SELECTED = 'SELECTED';
export const PHOTO_PARTNER_STATUS_SUBMITTED = 'SUBMITTED';
export const PHOTO_PARTNER_STATUS_ON_SALE = 'ON_SALE';
export const PHOTO_PARTNER_STATUS_REJECTED = 'REJECTED';
export const PHOTO_PARTNER_STATUS_REMOVED = 'REMOVED';

// PHOTO RELEASE STATUS
export const PHOTO_RELEASE_STATUS_NOT_NEEDED = 0;
export const PHOTO_RELEASE_STATUS_NEEDED = 1;
export const PHOTO_RELEASE_STATUS_REQUESTED = 2;
export const PHOTO_RELEASE_STATUS_CLEARED = 3;

// RELEASE STATUS
export const RELEASE_STATUS_NEEDED = 'NEEDED';
export const RELEASE_STATUS_REQUESTED = 'REQUESTED';
export const RELEASE_STATUS_CLEARED = 'CLEARED';
export const RELEASE_STATUS_DENIED = 'DENIED';

// RELEASE TYPE
export const RELEASE_TYPE_EMAIL = 0;
export const RELEASE_TYPE_DOCUMENT = 1;
export const RELEASE_TYPE_DECLINED = 2;

// PAYOUT STATUSES (api return strings not numbers)
export const PAYOUT_STATUS_PENDING = '1';
export const PAYOUT_STATUS_REQUESTED = '2';
export const PAYOUT_STATUS_PROCESSING = '3';
export const PAYOUT_STATUS_PAID = '4';
export const PAYOUT_STATUS_FAILED = '5';
export const PAYOUT_STATUS_LAPSED = '6';

export const PAYOUT_TAB_PENDING = 0;
export const PAYOUT_TAB_HISTORY = 1;

// ADDRESS TYPES
export const ADDRESS_TYPE_PRIVATE = 'PRIVATE';
export const ADDRESS_TYPE_BUSINESS = 'BUSINESS';

// SEARCH
export const SEARCH_SPELLCHECK_TYPE_REPLACE = 'REPLACE';
export const SEARCH_SPELLCHECK_TYPE_SUGGEST = 'SUGGEST';

export const SEARCH_FILTER_MARKET_STATUS_AVAILABLE = 'available';
export const SEARCH_FILTER_MARKET_STATUS_EDITORIAL = 'editorial';
export const SEARCH_FILTER_MARKET_STATUS_COMMERCIAL = 'commercial';
export const SEARCH_FILTER_MARKET_STATUS_NOT_AVAILABLE = 'not_available';

export const SEARCH_FILTER_FORMATS_PORTRAIT = 'portrait';
export const SEARCH_FILTER_FORMATS_LANDSCAPE = 'landscape';
export const SEARCH_FILTER_FORMATS_SQUARE = 'square';
export const SEARCH_FILTER_FORMATS_WIDESCREEN = 'widescreen';
export const SEARCH_FILTER_FORMATS_PANORAMA = 'panorama';

export const SEARCH_FILTER_STOCK_NOSTOCK = 'no_stock';
export const SEARCH_FILTER_STOCK_FLAGGED = 'flagged';
export const SEARCH_FILTER_STOCK_CONFIRMED = 'confirmed';

// export const SEARCH_FILTER_PRODOWNLOADS_ALL = '0';
export const SEARCH_FILTER_PRODOWNLOADS_ONLY_NEW = 'false';
export const SEARCH_FILTER_PRODOWNLOADS_ONLY_OLD = 'true';

export const SEARCH_FILTER_RELEASED_MODEL = 'model';

export const SEARCH_FILTER_PEOPLE_NO_PEOPLE = 'noPeople';
export const SEARCH_FILTER_PEOPLE_ONE_PERSON = 'onePerson';
export const SEARCH_FILTER_PEOPLE_TWO_PEOPLE = 'twoPeople';
export const SEARCH_FILTER_PEOPLE_GROUP_OF_PEOPLE = 'groupOfPeople';

export const SEARCH_FILTER_UPLOADED_SINCE_ANY = '0';
export const SEARCH_FILTER_UPLOADED_SINCE_7_DAYS = 'now-7d';
export const SEARCH_FILTER_UPLOADED_SINCE_14_DAYS = 'now-14d';
export const SEARCH_FILTER_UPLOADED_SINCE_1_MONTH = 'now-1M';
export const SEARCH_FILTER_UPLOADED_SINCE_2_MONTHS = 'now-2M';
export const SEARCH_FILTER_UPLOADED_SINCE_6_MONTHS = 'now-6M';
export const SEARCH_FILTER_UPLOADED_SINCE_1_YEAR = 'now-1y';

export const SEARCH_FILTER_REVIEWED_AFTER_ANY = '0';
export const SEARCH_FILTER_REVIEWED_AFTER_7_DAYS = 'now-7d';
export const SEARCH_FILTER_REVIEWED_AFTER_14_DAYS = 'now-14d';
export const SEARCH_FILTER_REVIEWED_AFTER_1_MONTH = 'now-1M';
export const SEARCH_FILTER_REVIEWED_AFTER_2_MONTHS = 'now-2M';
export const SEARCH_FILTER_REVIEWED_AFTER_6_MONTHS = 'now-6M';
export const SEARCH_FILTER_REVIEWED_AFTER_1_YEAR = 'now-1y';

export const SEARCH_FILTER_SIGNEDUP_AFTER_ANY = '0';
export const SEARCH_FILTER_SIGNEDUP_AFTER_7_DAYS = 'now-7d';
export const SEARCH_FILTER_SIGNEDUP_AFTER_14_DAYS = 'now-14d';
export const SEARCH_FILTER_SIGNEDUP_AFTER_1_MONTH = 'now-1M';
export const SEARCH_FILTER_SIGNEDUP_AFTER_2_MONTHS = 'now-2M';
export const SEARCH_FILTER_SIGNEDUP_AFTER_6_MONTHS = 'now-6M';
export const SEARCH_FILTER_SIGNEDUP_AFTER_1_YEAR = 'now-1y';

export const SEARCH_FILTER_MIN_RESOLUTION_ANY = '0';
export const SEARCH_FILTER_MIN_RESOLUTION_3MP = '3';
export const SEARCH_FILTER_MIN_RESOLUTION_9MP = '9';
export const SEARCH_FILTER_MIN_RESOLUTION_12MP = '12';
export const SEARCH_FILTER_MIN_RESOLUTION_16MP = '16';

export const SEARCH_FILTER_GRAYSCALE_ANY = '0';
export const SEARCH_FILTER_GRAYSCALE_COLOR = 'false';
export const SEARCH_FILTER_GRAYSCALE_BLACK_AND_WHITE = 'true';

export const SEARCH_FILTER_MARKET_SCORE_GREAT = 'great';
export const SEARCH_FILTER_MARKET_SCORE_REJECTED = 'rejected';
export const SEARCH_FILTER_MARKET_SCORE_POOR = 'poor';
export const SEARCH_FILTER_MARKET_SCORE_AVERAGE = 'average';
export const SEARCH_FILTER_MARKET_SCORE_GOOD = 'good';
export const SEARCH_FILTER_MARKET_SCORE_VERY_GOOD = 'very_good';

export const SEARCH_FILTER_AGE_GROUP_BABY = 'baby';
export const SEARCH_FILTER_AGE_GROUP_CHILD = 'child';
export const SEARCH_FILTER_AGE_GROUP_TEENAGER = 'teenager';
export const SEARCH_FILTER_AGE_GROUP_YOUNG_ADULT = 'young_adult';
export const SEARCH_FILTER_AGE_GROUP_MID_ADULT = 'mid_adult';
export const SEARCH_FILTER_AGE_GROUP_MATURE_ADULT = 'mature_adult';
export const SEARCH_FILTER_AGE_GROUP_SENIOR = 'senior';

export const SEARCH_FILTER_ABSTRACT_CONCEPTS_CLOSE_UP = 'close_up';
export const SEARCH_FILTER_ABSTRACT_CONCEPTS_DIRECTLY_ABOVE = 'directly_above';
export const SEARCH_FILTER_ABSTRACT_CONCEPTS_AERIAL_VIEW = 'aerial_view';
export const SEARCH_FILTER_ABSTRACT_CONCEPTS_LOOKING_AT_CAMERA =
  'looking_at_camera';
export const SEARCH_FILTER_ABSTRACT_CONCEPTS_DIMINISHING_PERSPECTIVE =
  'diminishing_perspective';
export const SEARCH_FILTER_ABSTRACT_CONCEPTS_BLURRED = 'blurred';
export const SEARCH_FILTER_ABSTRACT_CONCEPTS_BACKGROUNDS = 'backgrounds';
export const SEARCH_FILTER_ABSTRACT_CONCEPTS_COPY_SPACE = 'copy_space';
export const SEARCH_FILTER_ABSTRACT_CONCEPTS_SILHOUTTE = 'silhouette';

export const SEARCH_FILTER_REPLACE_QUERY_TRUE = 'true';
export const SEARCH_FILTER_REPLACE_QUERY_FALSE = 'false';

export const SEARCH_FILTER_COLLECTION_NONE = '0';
export const SEARCH_FILTER_COLLECTION_PREMIUM = 'premium';
export const SEARCH_FILTER_COLLECTION_ESSENTIAL = 'essential';
export const SEARCH_FILTER_COLLECTION_MIXED = 'mixed';

export const SEARCH_SORT_RELEVANCE = 'relevance';
export const SEARCH_SORT_CHRONOLOGICAL = 'chronological';
export const SEARCH_SORT_AESTHETICS = 'aesthetics';

export const SEARCH_BASE_URL_CONST = '/search/pictures';
export const SEARCH_ILLUSTRATION_BASE_URL_CONST = '/search/illustrations';

export const COLLECTION_SORT_ORDER_CHRONOLOGICAL = 'chronological';
export const COLLECTION_SORT_ORDER_TOP = 'top';

export const DEVICE_TYPE_PHONE = 'mobile';
export const DEVICE_TYPE_TABLET = 'tablet';
export const DEVICE_TYPE_DESKTOP = 'desktop';
export const DEVICE_TYPE_BOT = 'bot';

export const DEVICE_OS_ANDROID = 'Android';
export const DEVICE_OS_IOS = 'iOS';

export const USER_GRID_STEP_SIZE = 21;

export const PAGINATION_PARADIGM_SHOW_MORE = 'showMore';
export const PAGINATION_PARADIGM_AUTO = 'auto';
export const PAGINATION_PARADIGM_MANUAL = 'manual';

export const PAGINATION_MAX_PAGE = 100;

export const GRID_STEP_SIZE = {
  [PAGINATION_PARADIGM_AUTO]: 20,
  [PAGINATION_PARADIGM_MANUAL]: 100,
  [PAGINATION_PARADIGM_SHOW_MORE]: 30,
};

export const JUSTIFIED_GRID_NORMALIZED_WIDTHS = {
  buyer: {
    [DEVICE_TYPE_PHONE]: 1,
    [DEVICE_TYPE_TABLET]: 3,
    [DEVICE_TYPE_DESKTOP]: 7,
  },
  photographer: {
    [DEVICE_TYPE_PHONE]: 1,
    [DEVICE_TYPE_TABLET]: 3,
    [DEVICE_TYPE_DESKTOP]: 4,
  },
};
export const LAST_ROW_HEIGHT = 250;

export const ENTERPRISE_BCG = '4931ca29-7b53-4f72-9769-22e551610b6c';
export const ENTERPRISE_EYEEM_ROBOT_CORP =
  '3f4c1cb0-a909-4369-9d53-ac6419add0db';
export const ENTERPRISE_ING = '8f7c40ce-014b-420d-9f78-a864fda9bfe6';
export const ENTERPRISE_PICSART = '89fd2b8e-951f-4259-8829-55f06bd2c622';

export const AVAILABLE_CONTACT_FORM_VERSIONS = [
  'long',
  'short',
  'cart',
  'jobs',
  'support',
];

export const LIGHTBOXSTRIP_CLOSING_PATHS = [
  '/signup/:userType',
  '/confirm/:anything',
  '/settings/:page',
  '/lightboxes/:lightboxId',
  '/m/:missionId/dashboard/*',
  '/blog/:slug',
  '/market/*',
  '/',
  '/signup',
  '/login',
  '/lightboxes',
  '/password',
  '/missions/photographers',
  '/upload',
  '/upload/*',
  '/imprint',
  '/contact',
  '/press',
  '/pictures',
  '/bilder',
  '/privacy',
  '/tos',
  '/awards',
  '/terms/*',
  '/imprint',
  '/terms-mobile',
  '/privacy-mobile',
];

export const LIGHTBOX_PHOTO_HEIGHT = 136;

export const TOOLTIP_POSITION_ABOVE_CENTER = 'above-center';
export const TOOLTIP_POSITION_ABOVE_RIGHT = 'above-right';
export const TOOLTIP_POSITION_BELOW = 'below';
export const TOOLTIP_POSITION_BELOW_CENTER = 'below-center';
export const TOOLTIP_POSITION_BELOW_LEFT = 'below-left';
export const TOOLTIP_POSITION_LEFT = 'left';
export const TOOLTIP_POSITION_LEFT_UP = 'left-up';
export const TOOLTIP_POSITION_RIGHT = 'right';

export const MAPS_API_KEY = 'AIzaSyDw7b5KLKp9sivDi1jAnwgzB6uTvn9GN7g';

export const MAX_ALBUMS_40 = 40;
export const MAX_ALBUMS_50 = 50;

export const PHOTO_UPLOAD_MAX_FILES = 50;
export const ILLUSTRATION_UPLOAD_MAX_FILES = 10;
export const UPLOAD_BYTE_MAX_SIZE = 45000000;
const DROPZONE_FILESIZE_BASE = 1024;
const UPLOAD_MB_MAX_SIZE = (
  UPLOAD_BYTE_MAX_SIZE /
  (DROPZONE_FILESIZE_BASE * DROPZONE_FILESIZE_BASE)
).toFixed(0);
export const UPLOAD_MAX_SIZE = UPLOAD_MB_MAX_SIZE; // 25 MB

export const UPLOAD_LOWRESOLUTION = 4000000; // pixel

export const DROPZONE_TIMEOUT = 600000; // 10min in milliseconds

export const ACTION_BAR_REGULAR = 'ACTION_BAR_REGULAR';
export const ACTION_BAR_RELEASES = 'ACTION_BAR_RELEASES';

export const PROGRESS_CART = 0;
export const PROGRESS_CHECKOUT = 1;
export const PROGRESS_DOWNLOAD = 2;

export const CHECKOUT_FORM_ADDRESS = 'checkoutAddress';
export const CHECKOUT_FORM_PAYMENT = 'checkoutPayment';
export const CHECKOUT_FORM_CONFIRM = 'checkoutConfirm';

// prettier-ignore
export const COUNTRIES = [
  'AF', 'AX', 'AL', 'DZ', 'AS', 'AD', 'AO', 'AI', 'AQ', 'AG', 'AR', 'AM', 'AW', 'AU',
  'AT', 'AZ', 'BS', 'BH', 'BD', 'BB', 'BY', 'BE', 'BZ', 'BJ', 'BM', 'BT', 'BO', 'BQ',
  'BA', 'BW', 'BV', 'BR', 'IO', 'BN', 'BG', 'BF', 'BI', 'CV', 'KH', 'CM', 'CA', 'KY',
  'CF', 'TD', 'CL', 'CN', 'CX', 'CC', 'CO', 'KM', 'CG', 'CD', 'CK', 'CR', 'CI', 'HR',
  'CU', 'CW', 'CY', 'CZ', 'DK', 'DJ', 'DM', 'DO', 'EC', 'EG', 'SV', 'GQ', 'ER', 'EE',
  'ET', 'FK', 'FO', 'FJ', 'FI', 'FR', 'GF', 'PF', 'TF', 'GA', 'GM', 'GE', 'DE', 'GH',
  'GI', 'GR', 'GL', 'GD', 'GP', 'GU', 'GT', 'GG', 'GN', 'GW', 'GY', 'HT', 'HM', 'VA',
  'HN', 'HK', 'HU', 'IS', 'IN', 'ID', 'IR', 'IQ', 'IE', 'IM', 'IL', 'IT', 'JM', 'JP',
  'JE', 'JO', 'KZ', 'KE', 'KI', 'KP', 'KR', 'KW', 'KG', 'LA', 'LV', 'LB', 'LS', 'LR',
  'LY', 'LI', 'LT', 'LU', 'MO', 'MK', 'MG', 'MW', 'MY', 'MV', 'ML', 'MT', 'MH', 'MQ',
  'MR', 'MU', 'YT', 'MX', 'FM', 'MD', 'MC', 'MN', 'ME', 'MS', 'MA', 'MZ', 'MM', 'NA',
  'NR', 'NP', 'NL', 'NC', 'NZ', 'NI', 'NE', 'NG', 'NU', 'NF', 'MP', 'NO', 'OM', 'PK',
  'PW', 'PS', 'PA', 'PG', 'PY', 'PE', 'PH', 'PN', 'PL', 'PT', 'PR', 'QA', 'RE', 'RO',
  'RU', 'RW', 'BL', 'SH', 'KN', 'LC', 'MF', 'PM', 'VC', 'WS', 'SM', 'ST', 'SA', 'SN',
  'RS', 'SC', 'SL', 'SG', 'SX', 'SK', 'SI', 'SB', 'SO', 'ZA', 'GS', 'SS', 'ES', 'LK',
  'SD', 'SR', 'SJ', 'SZ', 'SE', 'CH', 'SY', 'TW', 'TJ', 'TZ', 'TH', 'TL', 'TG', 'TK',
  'TO', 'TT', 'TN', 'TR', 'TM', 'TC', 'TV', 'UG', 'UA', 'AE', 'GB', 'US', 'UM', 'UY',
  'UZ', 'VU', 'VE', 'VN', 'VG', 'VI', 'WF', 'EH', 'YE', 'ZM', 'ZW'
];

// prettier-ignore
export const COUNTRIES_WITHOUT_PAYPAL = [
  'AF', 'AI', 'AM', 'AO', 'AQ', 'AS', 'AW', 'AX', 'AZ', 'BD', 'BF', 'BI',
  'BJ', 'BL', 'BN', 'BO', 'BQ', 'BT', 'BV', 'BY', 'CC', 'CD', 'CF', 'CG',
  'CI', 'CK', 'CM', 'CU', 'CV', 'CW', 'CX', 'DJ',  'EH', 'ER', 'ET',
  'FK', 'FM', 'GA', 'GG', 'GH', 'GM', 'GN', 'GQ', 'GS', 'GU', 'GW', 'GY',
  'HM', 'HT', 'IM', 'IO', 'IQ', 'IR', 'JE', 'KG', 'KH', 'KI', 'KM', 'KP',
  'LA', 'LB', 'LK', 'LR', 'LY', 'ME', 'MF', 'MG', 'MH', 'MK', 'ML', 'MM',
  'MN', 'MO', 'MP', 'MR', 'MS', 'MV', 'NA', 'NE', 'NF', 'NG', 'NP', 'NR',
  'NU', 'PG', 'PK', 'PM', 'PN', 'PR', 'PS', 'PY', 'RU', 'RW', 'SB', 'SD', 'SH',
  'SJ', 'SL', 'SO', 'SR', 'SS', 'ST', 'SX', 'SY', 'SZ', 'TD', 'TF', 'TG',
  'TJ', 'TK', 'TL', 'TM', 'TN', 'TO', 'TR', 'TV', 'TZ',  'UG', 'UM',
  'UZ', 'VA', 'VC', 'VG', 'VI', 'VU', 'WF', 'WS', 'YE', 'YT', 'ZM', 'ZW'
];

// prettier-ignore
export const EU_COUNTRIES = [
  'AT', 'BE', 'BG', 'DE', 'HR', 'CY', 'CZ', 'DK',
  'EE', 'FI', 'FR', 'GR', 'HU', 'IE', 'IT', 'LV',
  'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK',
  'SI', 'ES', 'SE', 'GB'
];

export const COUNTRIES_WHERE_TAXID_IS_REQUIRED = EU_COUNTRIES.filter(
  (country) => country !== 'DE'
);

// In these countries TAX ID is not only
// optional, but not available at all
export const COUNTRIES_WITHOUT_VAT = ['US', 'CH'];

// prettier-ignore
export const COUNTRIES_WITH_BUYER_ACCESS = [
  'US', 'IE', 'GB', 'DE', 'CH', 'NL',
  'CW', 'GG', 'HU', 'IM', 'LY', 'LI',
  'LU', 'MO', 'MT', 'MC', 'BQ', 'SX',
  'NO', 'SM', 'SE', 'IN', 'ID', 'IT',
  'TH', 'MY', 'PH', 'CA', 'FR', 'BR',
  'TR', 'ES', 'AU', 'PL', 'KR', 'MX',
  'VN', 'AR', 'JP', 'PT', 'MA',
  'RO', 'UA', 'BE', 'EG', 'GR', 'AT',
  'SG', 'HK', 'CO', 'AE', 'TW', 'PK',
  'GE', 'PE', 'SA', 'BD', 'BG', 'RS',
  'CZ', 'LT', 'DZ', 'ZA', 'CL', 'LK',
  'IL', 'NZ', 'DK', 'AL', 'NP', 'BY',
  'FI', 'HR', 'SK', 'IQ', 'NG', 'SI',
  'QA', 'EC', 'LB', 'LV', 'JO', 'KZ',
  'EE', 'CR', 'VE', 'DO', 'TN', 'PS',
  'BA', 'GT', 'KE', 'UY', 'KW', 'MK',
  'SV', 'JM', 'MD', 'BH', 'TT',
  'MM', 'PA', 'OM', 'NA', 'BN', 'GH',
  'AM', 'BW', 'ET', 'CY', 'KG', 'MV',
  'MU', 'CM', 'KH', 'TZ', 'YE', 'AZ',
  'NI', 'CI', 'HN', 'MZ', 'UZ', 'PF',
  'LS', 'MN', 'ME', 'DM', 'TO', 'IS',
  'PR', 'DJ', 'RW', 'SO', 'ER',
  'RE', 'BT', 'MG', 'BB', 'MW', 'PG',
  'MP', 'SY', 'BJ', 'PY', 'TL',
  'SL', 'AX', 'SZ', 'ZM', 'AO',
  'SR'
];

export const PHONE_NUMBERS_EYEEM = {
  eu: '+49 (0)30 403 659 50',
  world: '+1 (929) 207-9571',
};

export const STORELINK_EYEEM = {
  [DEVICE_OS_IOS]: 'http://itunes.apple.com/app/eyeem/id445638931',
  [DEVICE_OS_ANDROID]:
    'http://play.google.com/store/apps/details?id=com.baseapp.eyeem',
};

export const SELLER_ACCOUNT_TYPE_PRIVATE = 1;
export const SELLER_ACCOUNT_TYPE_BUSINESS = 2;

// base64 encoded black pixel
export const DUMMY_PIXEL =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';

// estimated device widths (used when rendering server-side)
export const APPROXIMATE_DEVICE_WIDTHS = {
  [DEVICE_TYPE_PHONE]: 400,
  [DEVICE_TYPE_TABLET]: 1050,
  [DEVICE_TYPE_DESKTOP]: 1280,
};

// list of internal errorcodes to be used everwhere within frontend
export const ERROR_CODE = {
  EMAIL_TAKEN: '1',
  AUTH_UNKNOWN: '2',
  LOGIN_WRONG_CREDENTIALS: '3',
};

export const MARKET_DETAILS_MODE = {
  ADD_TO_MARKET: 'MARKET_DETAILS_MODE_ADD_TO_MARKET',
  DIRECT_DOWNLOAD: 'MARKET_DETAILS_MODE_DIRECT_DOWNLOAD',
  FOREIGN_ASSET_ON_SALE: 'MARKET_DETAILS_MODE_FOREIGN_ASSET_ON_SALE',
  JOIN_AS_SELLER: 'MARKET_DETAILS_MODE_JOIN_AS_SELLER',
  NOT_FOR_SALE: 'MARKET_DETAILS_MODE_NOT_FOR_SALE',
  NO_PERMISSION: 'MARKET_DETAILS_MODE_NO_PERMISSION',
  ON_HOLD: 'MARKET_DETAILS_MODE_ON_HOLD',
  OWN_PHOTO_ON_SALE: 'MARKET_DETAILS_MODE_OWN_PHOTO_ON_SALE',
  PENDING: 'MARKET_DETAILS_MODE_PENDING',
  PENDING_ILLUSTRATION: 'MARKET_DETAILS_MODE_PENDING_ILLUSTRATION',
  PURCHASED: 'MARKET_DETAILS_MODE_PURCHASED',
  REFUSED: 'MARKET_DETAILS_MODE_REFUSED',
  ILLUSTRATION: {
    IN_REVIEW: 'MARKET_DETAILS_ILLUSTRATION_IN_REVIEW',
    ON_SALE: 'MARKET_DETAILS_ILLUSTRATION_ON_SALE',
    NOT_ON_SALE: {
      IMAGE_SIMILARITY: 'MARKET_DETAILS_ILLUSTRATION_NOT_ON_SALE_SIMILARITY',
      WATERMARKS: 'MARKET_DETAILS_ILLUSTRATION_NOT_ON_SALEWATERMARKS',
      LACK_OF_MARKET_DEMAND:
        'MARKET_DETAILS_ILLUSTRATION_NOT_ON_SALE_LACK_OF_MARKET_DEMAND',
      TERMS_OF_SERVICE:
        'MARKET_DETAILS_ILLUSTRATION_NOT_ON_SALE_TERMS_OF_SERVICE',
    },
  },
};

// Release Management Overflow Menu Actions
export const OVERFLOW_EDIT_RELEASES = 'OVERFLOW_EDIT_RELEASES';
export const OVERFLOW_REMOVE = 'OVERFLOW_REMOVE';
export const OVERFLOW_CONTACT_TO_REMOVE = 'OVERFLOW_CONTACT_TO_REMOVE';

export const MISSION_STATUS_IN_PREPARATION = 'IN_PREPARATION';
export const MISSION_STATUS_READY_TO_LAUNCH = 'READY_TO_LAUNCH';
export const MISSION_STATUS_RUNNING = 'RUNNING';
export const MISSION_STATUS_SELECTING_WINNERS = 'SELECTING_WINNERS';
export const MISSION_STATUS_COMPLETED = 'COMPLETED';

export const FOLLOWING_FEED = '/following';

export const NAVBAR_HEIGHT = 64;

export const FLYOUT_TIMEOUT = 4000;

export const DROPDOWN_TRIGGER_STYLE_DARK = 'dark';
export const DROPDOWN_TRIGGER_STYLE_TRANSPARENT_ON_BRIGHT =
  'transparentOnBright';
export const DROPDOWN_TRIGGER_STYLE_TRANSPARENT_ON_DARK = 'transparentOnDark';
export const DROPDOWN_TRIGGER_STYLE_GREY = 'grey';

export const FROONT_URLS = ['/festival', '/selects'];

export const PREVIOUS_DOWNLOADS_PHOTOS_PATH = '/collection/photos';
export const PREVIOUS_DOWNLOADS_ILLUSTRATIONS_PATH =
  '/collection/illustrations';

// paths with sidenav
export const SIDENAV_PATHS = [
  '/',
  '/a/:albumId',
  '/a/:albumId/:paginatableName',
  '/bilder',
  '/collection',
  '/collection*',
  '/following',
  '/i/:assetId',
  '/i/:assetId/*',
  '/lightboxes*',
  '/lightboxes/:lightboxId',
  '/m/:missionId',
  '/m/:missionId/*',
  '/market/download/*',
  '/market/sell/*',
  '/market/sell/keywording',
  '/market/transactions',
  '/missions',
  '/missions/*',
  '/p/:assetId',
  '/p/:assetId/*',
  '/pictures',
  '/pictures/*',
  '/production/book',
  '/rm/:assetId',
  '/search/*',
  '/settings/*',
  '/u/:nickname',
  '/u/:nickname/:paginatableName',
  '/upload',
  '/upload/*',
];

export const NO_FOOTER_PATHS = [...SIDENAV_PATHS, '/market/cart'];

// webflow urls hosted in an own project
export const WEBFLOW_CAMPAIGN_URLS = ['/campaign/*', '/de/campaign/*'];

export const WEBFLOW_URLS_WITHOUT_REPLACEMENT = [
  ...WEBFLOW_CAMPAIGN_URLS,
  '/allianz',
  '/licensing/finance-pictures',
  '/pages/*',
];

export const WEBFLOW_URLS = [
  ...WEBFLOW_URLS_WITHOUT_REPLACEMENT,
  '/awards',
  '/awards-2021-terms-of-entry',
  '/awards-2021-terms-of-entry/*',
  '/awards/*',
  '/case-studies/*',
  '/collective',
  '/collective/*',
  '/creators',
  '/custom',
  '/imprint',
  '/jobs',
  '/licensing',
  '/magazine',
  '/magazine/*',
  '/market/licensing',
  '/market/pricing',
  '/missions',
  '/personalized-search',
  '/press',
  '/privacy',
  '/privacy-mobile',
  '/privacy-update',
  '/production/book',
  '/productions',
  '/sell-your-photos', // in case some traffic still happens to the old page, can be deleted at a later time
  '/sell-your-work',
  '/story',
  '/terms-mobile',
  '/terms/*',
  '/testing/*',
  '/tos',
  '/upforcollab/*',
  '/video',
  '/video/*',
  '/visualtrends',
];

export const ZAPIER_HOOK_ID_PURCHASE = 'h2yny2';
export const ZAPIER_HOOK_ID_SIGNUP = '8z342l';
export const ZAPIER_HOOK_ID_CONTACT_MARKET = '6yzrkt';
export const ZAPIER_HOOK_ID_CONTACT_CURATIONS = '6yz873';
export const ZAPIER_HOOK_ID_CONTACT_PARTNERSHIPS = '6yzzi3';
export const ZAPIER_HOOK_ID_CONTACT_MISSIONS = '6onpj0';
export const ZAPIER_HOOK_ID_CONTACT_SHORT = '904x6y';
export const ZAPIER_HOOK_ID_CONTACT_INVOICE = 'r43787';

export const ZAPIER_RECIPIENTS_MARKET = 'zapierMarket';
export const ZAPIER_RECIPIENTS_CURATIONS = 'zapierCurations';
export const ZAPIER_RECIPIENTS_PARTNERSHIPS = 'zapierPartnerships';
export const ZAPIER_RECIPIENTS_MISSIONS = 'zapierMissions';
export const ZAPIER_RECIPIENTS_SHORT = 'zapierShort';
export const ZAPIER_RECIPIENTS_INVOICE = 'zapierInvoice';

export const FEATURE_FLAG_COUNTRY = 'countryOverride';
export const FEATURE_FLAG_LOCALIZATION = 'localization';
export const FEATURE_FLAG_RELATED_SEARCH_TERMS = 'relatedSearchTerms';
export const FEATURE_FLAG_SPELL_CHECK = 'spellCheck';
export const FEATURE_FLAG_ILLUSTRATION_UPLOAD_MULTISELECT =
  'illustrationUploaderMultiselect';
export const FEATURE_FLAG_SIMILARITY_SEARCH = 'similaritySearch';

export const COOKIE_RECENT_AUTH_KEY = 'recentLoginOrSignup';
export const COOKIE_RECENT_AUTH_MAX_AGE = 60;
export const COOKIE_RECENT_AUTH_VALUE = 'true';

export const SESSION_FIELD_LIGHTBOX_STRIP_OPEN = 'lightboxStripOpen';
export const SESSION_FIELD_SELECTED_LIGHTBOX_ID = 'selectedLightbox';
export const SESSION_FIELD_SEARCH_SIDEBAR_OPEN = 'searchSidebar';
export const SESSION_FIELD_SEARCH_FILTERS_OPEN = 'searchFiltersOpen';
export const SESSION_FIELD_HAS_SIDE_NAV = 'hasSideNav';
export const SESSION_FIELD_HAS_VISITED_SEARCH = 'hasVisitedSearch';
export const SESSION_FIELD_SIDE_NAV_OPEN = 'isSideNavOpen';
export const SESSION_FIELD_CONVERSION_EVENT_OPTIONS = 'conversionEventOptions';
export const SESSION_FIELD_CANT_GET_RELEASES_MODAL_SEEN =
  'cantGetReleasesModalSeen';
export const SESSION_FIELD_COPYRIGHT_AGREEMENT_GIVEN =
  'copyrightAgreementGiven';
export const SESSION_FIELD_COPYRIGHT_AGREEMENT_GIVEN_ILLUSTRATIONS =
  'copyrightAgreementGivenIllustrations';
export const SESSION_FIELD_LAST_DOWNLOAD_REQUIREMENTS_VALUES =
  'lastDownloadRequirementsValues';

export const SESSION_FIELD_DEFAULT_BANNER_DISMISSED = 'defaultBannerDismissed';
export const SESSION_FIELD_SPECIAL_BANNER_DISMISSED = 'specialBannerDismissed';
export const SESSION_FIELD_HAS_SEEN_TOS_UPDATE_NOV_2023 =
  'hasSeenTOSUpdateNov2023';
export const SESSION_FIELD_DISCOUNT_CODE_VALID = 'discountCodeValid';

export const SESSION_FIELD_PASSWORD_RESET_TOKEN = 'passwordResetToken';

// export const EXPERIMENT_GRID_SIZE = 'exp_gridSize';
export const EXPERIMENT_SEARCH_FILTER = 'exp_searchFilter';

export const TEMP_FIELD_LIGHTBOX_ANIM_DISABLED = 'isLightboxAnimDisabled';
export const TEMP_FIELD_CONVERSION_EVENT_OPTION = 'conversionEventOption';

// SIGNUP FLOW
export const SIGNUP_FLOW_BUYER = 'BUYER';
export const SIGNUP_FLOW_CREATOR = 'CREATOR';
export const ACCOUNT_TYPE_CREATOR = 1;
export const ACCOUNT_TYPE_SELLER = 2;
export const ACCOUNT_TYPE_BUYER = 3;

// COOKIES
export const JWT_COOKIE_NAME = 'jwt';

export const USER_TYPE_SELFSERVE: 'SELFSERVICE' = 'SELFSERVICE';
export const USER_TYPE_ENTERPRISE: 'ENTERPRISE' = 'ENTERPRISE';
export const USER_TYPE_PHOTOGRAPHER: 'PHOTOGRAPHER' = 'PHOTOGRAPHER';

export const AUTH_INTENT_LOGIN = 'LOGIN';
export const AUTH_INTENT_SIGNUP = 'SIGNUP';

// ENTERPRISE CUSTOMER USER ROLES
export const CUSTOMER_ADMIN = 'customer.admin';
export const CUSTOMER_BUYER = 'customer.buyer';
// On the enterprise service our viewer role is called member.
// switching to viewer would have caused data migrations on the backend
export const CUSTOMER_VIEWER = 'customer.member';

// PAYMENT TYPES
export const PAYMENT_METHOD_PAYPAL = 'paypal';
export const PAYMENT_METHOD_CREDIT_CARD = 'creditCard';

// Enterprise purchase history filter types
export const USER: 'USER' = 'USER';
export const CUSTOMER: 'CUSTOMER' = 'CUSTOMER';

export const DEFAULT_LANGUAGE = 'en';

// todo: remove fr obviously
export const AVAILABLE_LANGUAGES = ['de', 'en'];

// CURRENCIES
export const CURRENCIES = {
  USD: '$',
  EUR: '€',
  GBP: '£',
};

// PRODUCTION URL
export const PRODUCTIONS_PRODUCTION_URL = 'https://production.eyeem.com';
export const PRODUCTIONS_PRODUCTION_TEST_URL =
  'https://productions-testing.eyeem.com';
export const PRODUCTIONS_STAGING_URL = 'https://production-staging.eyeem.com';
export const PRODUCTIONS_STAGING_TESTING_URL =
  'https://productions-staging-testing.eyeem.com';
export const PRODUCTIONS_DEVELOPMENT_URL = 'http://localhost:4000';
export const PRODUCTIONS_COOKIE_DOMAIN =
  // eslint-disable-next-line
  process.env.NODE_ENV === 'production'
    ? '.eyeem.com'
    : typeof window === 'object'
    ? window.eyeconfig?.server?.hostname
    : '';

export const PRODUCTIONS_HOSTNAME =
  typeof window === 'object' ? window.eyeconfig?.productionsFQDN : '';

export const TOGGLE_SIDENAV_PUBSUB_EVENT = 'toggleSideNav';

export const USER_PAGINATABLE_NAME_MAP = {
  follow: 'photos',
  followers: 'followers',
  following: 'followings',
  illustrations: 'illustrations',
  likedphotos: 'likedPhotos',
  market: 'marketPhotos',
  partner: 'partnerPhotos',
  photos: 'photos',
  releases: 'releasesNeededPhotos',
};

// 155 characters as maximum length for descriptions is a number recommended by our SEO agency
export const DESCRIPTION_MAX_LENGTH = 155;

// Set buy Ludo our former SEO guy
export const PAGE_TITLE_MAX_LENGTH = 60;

// OAUTH PROVIDERS
export const OAUTH = {
  BCG: 'bcg',
  GOOGLE: 'google',
  SELF: 'self',
};
export const SUGGESTED_SEARCH_TERMS = [
  { term: 'nature', title: 'search.hint.alternativeTerms.terms.nature' },
  {
    term: 'digital',
    title: 'search.hint.alternativeTerms.terms.digital',
  },
  {
    term: 'teamwork',
    title: 'search.hint.alternativeTerms.terms.teamwork',
  },
  {
    term: 'industry',
    title: 'search.hint.alternativeTerms.terms.industry',
  },
  {
    term: 'architecture',
    title: 'search.hint.alternativeTerms.terms.architecture',
  },
  {
    term: 'business',
    title: 'search.hint.alternativeTerms.terms.business',
  },
];

export const APPLE_APP_ID = 445638931;

export const PRODUCTION_BASEURL = 'https://www.eyeem.com';

export const DISCOUNT_STATUS = {
  VALID: 'valid',
  INVALID: 'invalid',
  REUSED: 'reused',
};

export const SHARE_LIGHTBOX_INVITE_STATUSCODES = {
  ADDED: 204,
  MAX_MEMBERS_REACHED_ERROR: 400,
};

export const KEYWORD_USER = 'USER';
export const KEYWORD_USER_SUGGESTED = 'USER_SUGGESTED';
export const KEYWORD_UNVALIDATED = 'UNVALIDATED';

export const PARTNER_SOURCE_LIGHTBOXES = [
  'fe9a2034-c3cb-449b-838a-7c14cb038581', // DNA
];

export const DEBOUNCE_WAIT = 300;
