/**
 * English translations file
 *
 * Used by react-translate-component as follows <T time="value">s.community</T>
 *
 * To use values that are passed as properties wrap them in `%(` and `)s%`. So
 * to use the `time` property from the above example, in your translation write
 * `My translation stuff at %(time)s o\'clock`
 *
 * You must escape an apostrophe if they delimit a quote. Escape with a backslash.
 *
 * Default properties available in translation strings:
 *
 * %(br)s - line break
 * %(br2)s - another line break, trololol
 *
 */

export default {
  maintenance: {
    default: {
      headline: 'EyeEm under maintenance',
      subheadline:
        'Sorry for the inconvenience. EyeEm is undergoing scheduled maintenance from 9:30am - 12:30pm CET. For urgent matters please contact sales@eyeem.com or support@eyeem.com.',
    },
    footer: '© %(year)s EyeEm Mobile GmbH.',
  },
  defaultBanner: {
    linkText: 'Buy for less.',
    text: 'Get every image at 80%% off with our flexible subscriptions.',
  },
  specialBanner: {
    preCodeText: "It's Cyber Week! Use code ",
    code: 'flashfriday2021',
    copied: 'Promo code copied to clipboard',
    postCodeText: " for 30%% off your next purchase - don't miss out!",
  },
  premium: {
    label: 'Premium',
    info: 'Our Premium Collection combines all high-quality visuals, designed to push creative boundaries without compromising on quality.',
    subline: {
      label: 'Premium Collection: ',
    },
  },
  downloadHistory: {
    enterprise: {
      headline: 'Download History',
      text: 'All images below have been licensed or downloaded as high-res previews.',
      feedName: 'Download History',
      filter: 'My Downloads Only',
    },
    empty: {
      photos: {
        icon: 'photos',
        headline: 'You haven’t licensed any photos yet.',
        subline: 'Once you license a photo, you’ll see it here.',
      },
      illustrations: {
        icon: 'photos',
        headline: 'You haven’t licensed any illustrations yet.',
        subline: 'Once you license an illustration, you’ll see it here.',
      },
    },
  },
  lightboxes: {
    strip: {
      select: {
        noLightboxSelected: 'Default Lightbox',
        create: {
          label: 'Create new Lightbox',
          eventName: 'access_createLightbox',
          eventPosition: 'lightboxStrip_dropdown',
        },
        view: {
          label: 'View',
          eventName: 'access_lightbox',
          eventPosition: 'lightboxStrip_dropdown',
        },
        eventName: 'lightbox_switchStrip',
        eventPosition: 'lightboxStrip_dropdown',
      },
      manage: {
        label: 'Lightbox Overview',
        eventName: 'access_lightbox_overview',
      },
      viewAllPhotos: {
        label: 'View Lightbox',
        eventName: 'access_lightbox',
      },
      removePhoto: {
        iconAlt: 'Remove from lightbox',
        eventName: 'photo_removeFromLightbox',
      },
      accessAsset: {
        eventName: 'access_asset',
        eventLabel: '',
      },
      eventPosition: 'lightboxStrip',
      empty: {
        first: 'Click on the ',
        second: ' icon on a image to add it to your Lightbox',
      },
      loading: 'Loading photos',
    },
    actions: {
      duplicate: {
        label: 'Duplicate',
        eventName: 'access_duplicateLightbox',
      },
      delete: {
        label: 'Delete',
        eventName: 'access_deleteLightbox',
      },
      editInfo: {
        label: 'Edit',
        eventName: 'access_editLightbox',
      },
      eventLabel: 'lightboxActions',
      eventName: 'access_lightboxActions',
      select: {
        label: 'Select',
      },
      share: {
        label: 'Share',
        eventName: 'access_lightboxShare',
      },
      trigger: 'Edit Lightbox',
    },
    singleView: {
      client: '%(client)s - ',
      date: {
        one: 'last updated %(count)s day ago',
        other: 'last updated %(count)s days ago',
      },
      empty: {
        icon: 'photos',
        headline: 'You haven’t added any photos to your Lightbox yet.',
        subline: 'Once you add a photo, you’ll see it here.',
      },
      eventPosition: 'lightbox',
      totalPhotos: {
        one: '1 Image - ',
        other: '%(count)s Images - ',
      },
    },
    modals: {
      lightboxInvite: {
        title: 'Share Lightbox',
        inviteButton: 'Share',
        collaborationInfo:
          "Users with access can edit it's content and share with other team members.",
        viewOnlyTitle: 'Can view',
        viewOnlyInfo: 'Users with this link can view the file.',
        viewOnlyLink: 'Copy link',
        owner: 'Owner',
        canEdit: 'Can edit',
        inputPlaceholder: 'Add a team member',
        linkCopied: 'Copied!',
        error: 'Something went wrong. Please try again.',
        maxMemberError: 'Maximum number of members added.',
        successMessage: '%(email)s invited',
      },
      create: {
        cancel: {
          label: 'Cancel',
          eventName: 'lightboxCreate_cancel',
        },
        confirm: {
          label: 'Create',
          eventName: 'lightboxCreate_save',
        },
        eventPosition: 'lightbox_modal_create',
        title: 'Create Lightbox',
      },
      delete: {
        body: 'Be careful by deleting, you could be removing this Lightbox for other contributors as well.',
        cancel: {
          label: 'Cancel',
          eventName: 'lightboxDelete_cancel',
        },
        confirm: {
          label: 'Delete',
          eventName: 'lightboxDelete_save',
        },
        eventPosition: 'lightbox_modal_delete',
        title: 'Delete Lightbox',
      },
      duplicate: {
        cancel: {
          label: 'Cancel',
          eventName: 'lightboxDuplicate_cancel',
        },
        confirm: {
          label: 'Duplicate',
          eventName: 'lightboxDuplicate_save',
        },
        eventPosition: 'lightbox_modal_duplicate',
        name: 'Copy of %(name)s',
        title: 'Duplicate Lightbox',
      },
      form: {
        label: {
          name: 'Name',
          clientDescription: 'Client (Optional)',
          description: 'Description (Optional)',
        },
        placeholder: {
          name: "Short name that's easy to understand",
          clientDescription: 'Is this Lightbox for a specific client?',
          description: 'Describe the Lightboxes goal or requirements',
        },
      },
      share: {
        headline: 'Share Lightbox',
        button: {
          copy: 'Copy link',
          copied: 'Copied!',
        },
        eventName: 'lightboxShare_copy',
        eventPosition: 'lightbox_modal_share',
      },
      update: {
        cancel: {
          label: 'Cancel',
          eventName: 'lightboxUpdate_cancel',
        },
        confirm: {
          label: 'Save',
          eventName: 'lightboxUpdate_save',
        },
        eventPosition: 'lightbox_modal_update',
        title: 'Edit Lightbox details',
      },
    },
    flyout: {
      photos: {
        added: 'Photo added to',
        removed: 'Photo removed from',
      },
      illustrations: {
        added: 'Illustration added to',
        removed: 'Illustration removed from',
      },
    },
    overview: {
      headline: 'My Lightboxes',
      total: '%(number)s lightboxes',
      view: 'View Lightbox',
      create: {
        label: 'New Lightbox',
        eventName: 'access_createLightbox',
      },
      tableHead: {
        name: 'Name',
        client: 'Client',
        photos: 'Photos',
        date: 'Date',
      },
      accessTracking: {
        eventName: 'access_lightbox',
      },
      share: {
        label: 'Share Lightbox',
        eventName: 'lightboxShare_copy',
      },
      eventPosition: 'lightbox_overview',
      emptyState:
        'This is where your Lightboxes will be stored. Get started by clicking ‘Create new Lightbox’ – you can create as many as you like.',
    },
    addToLightbox: {
      defaultName: 'My Lightbox',
      shortlist: 'Shortlist',
      winners: 'Winners',
      add: {
        label: 'Save to %(lightboxName)s',
        regular: {
          eventName: 'photo_addToLightbox',
        },
        shortlist: {
          eventName: 'missionb2b_shortlist_add',
        },
        winners: {
          eventName: 'missionb2b_winner_add',
        },
      },
      remove: {
        label: 'Remove from %(lightboxName)s',
        regular: {
          eventName: 'photo_removeFromLightbox',
        },
        shortlist: {
          eventName: 'missionb2b_shortlist_remove',
        },
        winners: {
          eventName: 'missionb2b_winner_remove',
        },
      },
      eventLabel: 'icon',
      gridEventPosition: 'assetgrid',
      pviewEventPosition: 'pv_stage',
    },
  },
  authForms: {
    or: 'OR',
    openId: {
      eventPosition: 'login_openId',
      headline: 'Connect to EyeEm',
      subline:
        "Sign in with your %(provider)s credentials to access imagery from EyeEm's global community of photographers.",
      errors: {
        generic:
          "Sorry, we couldn't sign you into EyeEm. Please try again later or contact your account manager.",
      },
      providers: {
        bcg: 'BCG',
        google: 'Google',
      },
      button: {
        label: 'Connect',
        eventName: 'openIdLogin_connect',
      },
      googleButton: {
        loginLabel: 'Log in with Google',
        signupLabel: 'Sign up with Google',
      },
      creatorLoginError:
        'Not available for photographer accounts at the moment. Please use the form below to log in.',
      emailAlreadyRegistered:
        'Email already in use. Please login instead or use a different Email to sign up.',
      noAccoutFoundError:
        'No business/image buyer account is associated with this email. Sign up or use a different email to log in.',
    },
    login: {
      headline: 'Welcome back.',
      facebook: 'Log in with Facebook',
      email: 'Email',
      password: 'Password',
      rememberMe: 'Keep me logged in',
      submit: 'Log In',
      checkoutSubmit: 'Continue to checkout',
      forgotPassword: 'Forgot your password?',
      reset: 'Reset it here',
      noAccount: "Don't have an account yet? %(signup)s",
      signup: 'Sign up now',
      errorConflict:
        'Sorry, we could not log you in. Please check your input and try again.',
    },
    signup: {
      passwordScores: {
        1: {
          color: 'red50',
          text: 'Password too easy to guess',
          strength: 'Weak',
        },
        2: {
          color: 'orange50',
          text: 'Password could be improved',
          strength: 'Medium',
        },
        3: { color: 'aqua50', strength: 'Strong' },
      },
      email: 'Email',
      password: 'Password',
      errorConflict: 'Please provide another email address',
      submit: {
        label: 'Create my free account',
        eventName: {
          photographer: 'photographerSignup_complete_%(suffix)s',
          buyer: 'buyerSignup_complete_%(suffix)s',
          enterprise: 'enterpriseSignup_complete_%(suffix)s',
        },
      },
      legalSubline:
        'By clicking the "Sign up" button you agree to EyeEm\'s %(tos)s and %(privacy)s.',
      tos: 'Terms',
      privacy: 'Privacy Policy',
      haveAnAccount: 'Already have an account? ',
      haveAnAccountPlain: 'Already have an account? ',
      login: 'Login',
      facebook: 'Continue with Facebook',
    },
    enterpriseBuyerSignup: {
      eventPosition: 'enterprise_buyer_signup',
      headline: 'Join the %(customerName)s team on EyeEm',
      defaultHeadline: 'Join your team on EyeEm',
      fullName: 'Full name',
      signingUpAs: 'Signing up as ',
      submit: {
        label: 'Create Account',
      },
    },
    buyerSignup: {
      eventPosition: 'buyer_signup',
      headline: 'Create your business account',
      link: 'Want to sell your work? %(linkToSignup)s',
      linkToSignup: 'Sign up as a contributor',
      emailOptIn:
        'I want to receive updates on visual trends, products, and exclusive discounts. You can unsubscribe at any time.',
    },
    creatorSignup: {
      eventPosition: 'creator_signup',
      headline: "Create your contributor's account",
      link: 'Want to buy photos? %(linkToSignup)s',
      linkToSignup: 'Sign up as a buyer',
      emailOptIn:
        'I want to receive newletters and community updates. You can unsubscribe at any time.',
    },
    signupSwitch: {
      eventPosition: 'signup_split',
      buyer: {
        headline: 'Buy Stock Photos',
        subline:
          'Create a business account to license royalty-free photography and book photo shoots worldwide.',
        button: {
          label: 'Sign Up as Buyer',
          eventName: 'cta_signup_buyer',
        },
      },
      seller: {
        headline: 'Upload and Sell Your Work',
        subline:
          'Join a network of +8m creatives worldwide. Get hired for shoots and distribute your work.',
        button: {
          label: 'Sign Up as Contributor',
          eventName: 'cta_signup_seller',
        },
      },
    },
    requestPasswordReset: {
      headline: 'Request a password reset',
      subline:
        "Please enter your email address below and we'll send you a link to reset your password.",
      email: 'Email',
      unknownEmail:
        "We don't recognize this email address. Please double check you have entered it correctly.",
      submit: 'Request reset link',
      backToLogin: 'Back to log in',
      success: {
        headline: 'Email successfully sent!',
        subline:
          'If there is an account associated with this email, you will find a message with instructions on how to reset your password.',
        link: 'Go to EyeEm.com',
      },
    },
    resetPassword: {
      headline: 'Change your password',
      password: 'New Password',
      confirmPassword: 'Confirm your password',
      submit: 'Change password',
    },
    connectApp: {
      redirectHeadline: 'Already authorized, sending you back to "%(name)s".',
      headline: 'The app "%(name)s" would like to access your EyeEm account',
      submit: 'Authorize',
      link: 'Cancel',
    },
  },
  sideNav: {
    items: {
      licensing: 'Image Licensing',
      profile: 'Profile',
      following: 'Following',
      upload: 'Upload',
      missions: 'Missions',
      production: 'Production',
      account: 'Account',
      team: 'Team',
      more: 'More',
    },
    account: {
      title: 'Account',
      items: {
        orderHistory: 'Order History',
        settings: 'Settings',
        payout: 'Earnings',
        logout: 'Logout',
      },
    },
    more: {
      title: 'More',
      magazine: 'Magazine',
      items: {
        company: {
          title: 'Company',
          about: 'About EyeEm',
          tech: 'Our Technology',
          press: 'Press',
          careers: 'Jobs',
        },
        legal: {
          title: 'Legal',
          terms: 'Terms & Privacy',
          imprint: 'Imprint',
        },
        support: {
          title: 'Support',
          help: 'Need Help?',
        },
      },
    },
  },
  navbar: {
    pricing: 'Pricing',
    backToApp: 'Back to App',
    community: {
      label: 'Community',
      eventName: 'access_community',
      eventPosition: 'navbar_community',
      items: {
        missions: {
          label: 'Missions',
        },
        awards: {
          label: 'Awards',
          href: '/awards',
        },
        buyerSwitch: {
          label: 'For Business',
          href: '/',
          eventName: 'access_community_buyer',
        },
      },
    },
    logo: {
      eventName: 'access_eyeem',
      eventLabel: 'logo',
    },
    search: {
      cancel: 'Cancel',
    },
    products: {
      label: 'Products',
      eventName: 'access_products',
      eventPosition: 'navbar_products',
      items: {
        video: {
          label: 'Video',
          href: '/productions',
        },
        videoContribute: {
          label: 'Video',
          href: '/video/contribute',
        },
        licensing: {
          label: 'Buy Images',
          href: '/marketplace',
        },
        custom: {
          label: 'Book Photoshoots',
          href: '/productions',
        },
        searchPA: {
          label: 'Custom Image Search',
          href: '/personalized-search',
        },
        missions: {
          label: 'Missions',
        },
        pricing: {
          label: 'Pricing',
          href: '/market/licensing',
        },
        photographerSwitch: {
          label: 'For Photographers',
          href: '/creators',
          eventName: 'access_products_photographers',
        },
      },
    },
    missions: {
      label: 'Missions',
      eventName: 'access_missions',
    },
    signIn: {
      label: 'Sign In',
      eventName: 'access_signup_split',
    },
    upload: {
      label: 'Upload',
      eventName: 'upload_access',
      eventLabel: 'icon',
    },
    lightbox: {
      label: 'Lightbox',
      eventName: {
        open: 'access_lightboxStrip_open',
        close: 'access_lightboxStrip_close',
      },
      eventLabel: 'icon',
      flyout: {
        eventName: 'access_lightbox',
        eventPosition: 'navbar_lightboxFlyout',
      },
    },
    notifications: {
      tasks: {
        headline: 'To do',
        photoReleases: {
          headline: 'Ask for Model Releases',
          subline: 'Make your photos commercially available',
        },
        firstPhoto: {
          headline: 'Upload your first photo!',
        },
        verifyEmail: {
          headline: 'Please verify your e-mail',
          subline: 'Click for more information',
        },
        keywording: {
          headline: 'Confirm Market Photo Details',
          subline: 'Make photos findable and increase your chances of selling',
        },
      },
      news: {
        headline: 'Activity',
      },
      empty: 'You have no new notifications right now. Stay tuned!',
      eventName: 'access_news',
    },
    profileMenu: {
      profile: 'Profile',
      payoutHistory: 'Earnings',
      previousPurchases: 'Order History',
      demoCollection: 'Your Collection',
      bcgCollection: 'BCG Collection',
      productions: 'Productions',
      new: 'NEW',
      settings: 'Settings',
      logOut: 'Log Out',
      eventName: 'access_avatar',
      eventLabel: 'icon',
      availableDownloads: {
        TITLE: 'Available Downloads',
        regular: 'ESSENTIAL IMAGES',
        premium: 'PREMIUM IMAGES',
      },
      downloadsLeft: 'Downloads left',
      unlimited: 'Unlimited',
      renew: 'Renew',
    },
    licensesMenu: {
      label: 'Licenses',
      eventName: 'access_licenses',
      eventLabel: 'icon',
      emptyStateBuyer: {
        button: 'Buy Photo Pack',
        title: 'Save up to 20%% with packs.',
        subline:
          'Need multiple images? Buy packs to get a volume discount on your next downloads.',
      },
    },
    readMeFirst: {
      label: 'Read Me First',
      href: 'https://dtpuu5koy2g08.cloudfront.net/bcg/EyeEm-Read-Me-User-Guide-Nov-2018.pdf',
      eventName: 'access_readMe',
    },
    bcgCurations: {
      label: 'Curations',
      href: '/bcgcurations',
      eventName: 'access_bcgCurationsOnWebflow',
    },
    cart: {
      eventName: 'access_cart',
      eventLabel: 'icon',
    },
    myCollection: {
      label: 'Download History',
      eventName: 'access_myCollection',
      eventLabel: 'icon',
    },
    info: {
      newLabel: 'New!',
      buyStockPhotos: 'Buy Royalty-free Content',
      bookPhotoshoots: 'Book Photoshoots',
      pricing: 'Pricing',
      contactSales: 'Contact Sales',
      buttons: {
        login: 'Login',
        signup: 'Signup',
        creatorSignup: 'Sell your photos',
      },
      business: {
        label: 'For Business',
        licensing: 'Image Licensing',
        production: 'Photo & Video Production',
        pricing: 'Pricing & Licenses',
      },
      photographers: {
        label: 'For Contributors',
        join: 'Join the Community',
        sell: 'Sell Your Work',
        collective: 'Get Hired for Shoots',
        awards: 'EyeEm Awards',
        missions: 'Missions',
        support: 'Support',
      },
      more: {
        label: 'More',
        magazine: 'EyeEm Magazine',
        story: 'Our Story',
        tech: 'Our Technology',
        terms: 'Terms & Privacy',
        faq: 'FAQ & Help',
        jobs: 'Work With Us',
        contact: 'Contact Us',
      },
    },
    eventPosition: {
      photographer: 'navbar_photographer',
      buyer: 'navbar_buyer',
      enterprise: 'navbar_enterprise',
      naked: 'navbar_naked',
      info: 'navbar_info',
    },
  },
  feedLabel: {
    popular: 'Popular',
    following: 'Following',
  },
  collapsible: {
    expand: 'Expand',
    collapse: 'Collapse',
  },
  nsfw: {
    warning: {
      imgAlt: 'Sensitive photo cover',
      message: 'This photo may be sensitive',
      subtitle: {
        mobile: 'Tap to unhide',
        tablet: 'Tap to unhide',
        desktop: 'Click to unhide',
      },
    },
  },
  notifications: {
    supportEmail: 'support@eyeem.com',
    accountDeletionPayouts:
      'Account deletion failed. If you have unclaimed earnings, please pay them out before deleting your EyeEm account. Contact %(supportDeleteAccount)s if your unclaimed earnings are below 10$.',
    unverifiedUserSettingsUpdateHomepage:
      'Unable to set homepage. Please verify your e-mail first.',
    UserSettingsHomepageShortenerError:
      'Unable to set homepage. You cannot use URL shorteners.',
    searchFailed: 'Something went wrong with this search. Please try again.',
    sellerSignupFailed:
      "We couldn't complete your contributor signup. Please try again or contact support@eyeem.com",
    emailVerification: 'Your email address has been successfully verified.',
    submittedToMission:
      'Good job, you just submitted your work to this Mission. Fingers crossed!',
    settingsUpdate: 'Your settings have been updated.',
    accountCreated: 'Your account was successfully created',
    passwordReset: "Password changed! You're now logged in.",
    passwordResetEnterprise: 'Password changed! You can now log in.',
    serverError:
      'We seem to be experiencing technical problems. Please reload the page.',
    connectionSuccessful:
      'You successfully connected EyeEm with your %(service)s account.',
    connectionFailed: 'Connection with %(service)s failed. Please try again!',
    connectionFailedMultiple:
      "The Facebook account you're trying to connect to is already in use by another EyeEm account. Please contact support.",
    profileImageUploaded: 'Your profile photo has been updated successfully.',
    profileImageUploadFailed:
      'Your profile photo could not be uploaded, please try again!',
    coverPhotoUpdated: 'Your cover photo has been updated successfully.',
    loginErrorUnknown:
      "We couldn't log you in. Please make sure to fill all fields and try again or contact support@eyeem.com",
    signupEmailConflict:
      'Cannot sign up with this email. Please choose another.',
    signupErrorUnknown:
      "We couldn't sign you up. Please try again or contact support@eyeem.com",
    signupTokenError:
      'Something went wrong. Please try again or contact support@eyeem.com',
    enterpriseUserRegistered: 'You are now registered.',
    passwordResetExpiredToken:
      'The reset token is expired. Please visit eyeem.com/password and request a new password.',
    enterpriseInviteExpiredToken:
      'The invitation link has expired. Please contact support@eyeem.com to ask for a new invite.',
    invalidToken:
      'Your token is invalid. Please try again or contact support@eyeem.com',
    wrongCaptcha:
      'Captcha was not recognized. Please try again or contact support@eyeem.com',
    unexpectedUser:
      'This account can no longer make purchases on EyeEm Market. Please create a new account or contact support@eyeem.com',
    paypalUpdateError:
      'Whoops, something went wrong. Please check that you entered your name exactly as it appears in your PayPal account. Visit %(marketSupport)s for more information.',
    payoutRequestedSuccess:
      "Your payout of %(amount)s has been successfully requested. You'll receive an email as soon as the payout has been transferred.",
    cart: {
      merged:
        "You already had one or more images in your cart from a previous session, so we've added them here. Need help? %(getInTouch)s",
      notFoundPhotos: {
        one: 'An image from your cart was removed because it could no longer be found. Need help?  %(getInTouch)s',
        other:
          '%(count)s images from your cart were removed because they could no longer be found. Need help? %(getInTouch)s',
      },
      ownPhotos: {
        one: "An image from your cart was removed because it looks like you're the owner. Need help? %(getInTouch)s",
        other:
          "%(count)s images from your cart were removed because it looks like you're the owner. Need help? %(getInTouch)s",
      },
      alreadyBoughtPhotos: {
        one: "An image from your cart was removed because it looks like you've purchased it before. Need help? %(getInTouch)s",
        other:
          "%(count)s images from your cart were removed because it looks like you've purchased them before. Need help? %(getInTouch)s",
      },
      invalidLicense: {
        one: 'An image from your cart was removed because its license has changed. Need help? %(getInTouch)s',
        other:
          '%(count)s images from your cart were removed because their licenses have changed. Need help? %(getInTouch)s',
      },
      getInTouch: 'Contact Market Support',
    },
    addPhotosToAlbum:
      'Good job, you just submitted your work to this Mission. Fingers crossed!',
    missionContact:
      "Thanks for getting in contact! You'll hear back from us within 24 hours.",
    blockedlikeBecauseEmail:
      'You cannot like photos until you have verified your email address.',
    blockedfollowBecauseEmail:
      'You cannot follow users until you have verified your email address.',
    blockedCommentBecauseEmail:
      'You cannot comment on photos until you have verified your email address.',
    genericBackendError:
      'Ooops. Something went wrong. If the problem persists, please contact us at support@eyeem.com.',
    cantGetReleases: 'Photo was successfully removed from list.',
    userKeywording: {
      submissionFailed:
        'Submission failed. All fields must be filled out correctly.',
      submissionSucceeded: 'The photo was successfully sent for review.',
    },
    changeMarketEmail: 'Verification email sent!',
    uploadSuccessful: 'Upload successful!',
    uploadFailed: 'Upload failed, please double check your input!',
    paypalTokenExpiry:
      'The link has expired. Please request a new link by clicking on “Connect”.',
  },
  meta: {
    description: {
      default:
        'EyeEm is a free photo & editing app on iPhone & Android. Join our global creative community & marketplace and see where it takes you!',
      user: 'Discover the unique and authenthic photography of %(user)s on EyeEm',
      userPaginated:
        'Discover page %(page)s of authenthic photography by %(user)s on EyeEm.',
      userMarketplace: 'Buy authenthic photography by %(user)s on EyeEm.',
      userMarketplacePaginated:
        'Page %(page)s of %(user)s marketplace. Buy authenthic photography on EyeEm.',
      userFollowers: 'Discover the followers of %(user)s on EyeEm.',
      userFollowing: 'Discover who %(user)s is following on EyeEm.',
      photo:
        'Discover the photography %(photoId)s by %(user)s – Explore millions of royalty-free pictures from outstanding photographers with EyeEm',
      album:
        '%(albumName)s photos on EyeEm - see %(photoCount)s pictures from %(photographerCount)s photographers',
      contact:
        'Want to get in touch? Contact us today and share your ideas! We love to hear from the EyeEm Community.',
      collectionIndex:
        'Think beyond stock photography: Browse a wide range of images from curated collections, all available for purchase from awesome EyeEm photographers!',
      privacy:
        'We take privacy seriously at EyeEm. Read more about our privacy policy and procedures here.',
      tos: 'Sharing your photos on EyeEm? View the terms of service at EyeEm. If you have any queries, get in touch!',
      privacyDe:
        'Uns ist Dein Datenschutz sehr wichtig. Erfahre mehr über unsere Richtlinien hier.',
      tosDe:
        'Teilst Du deine Fotos auf EyeEm? Hier findest Du unsere Leistungsbedingungen. Du kannst uns gerne kontaktieren, wenn du Fragen hast!',
      imprint:
        'Imprint: EyeEm Mobile GmbH, c/o Mindspace, Max-Beer-Straße 2-4, 10119 Berlin, Germany',
      licensing:
        'We take licensing seriously, making sure your photography gets the attention it deserves at no loss to you. Learn more here.',
      sellerForm:
        'Shoot, upload, sell: Earning money with your photography made easy.',
      missions:
        'EyeEm is always looking to give back to the community. Get discovered: Enter a Mission and take your photography to the next level!',
      press:
        'Looking to share something with the EyeEm media team? Or looking for them to share something with you? Get in touch today - we can’t wait to hear from you!',
      upload:
        'Upload your images to EyeEm via the web today and get rewarded for your creativity. Learn more about making money from your photography.',
      jobs: 'Want to be a part of the EyeEm Team? We’re always eager to hear from like-minded people! Get in touch today for career opportunities in Berlin and New York.',
      mlp: 'Beyond stock photography: EyeEm is the place for photographers and image buyers to get the right shot. Browse and buy online with EyeEm today!',
      story: 'Our Story',
      mission:
        'Check out our latest mission %(missionTitle)s with %(partnerName)s. Submit your photos to get published worldwide and win great prizes.',
      missionPhotos:
        'Check out the entries of %(missionTitle)s from %(partnerName)s. Discover beautiful photography and get inspired!',
      missionPhotosPaginated:
        'Page %(page)s of %(missionTitle)s from %(partnerName)s. Discover unique photography on EyeEm.',
      missionPhotographers:
        'Discover the current photography competition on EyeEm. Submit photos, get published and win great prizes.',
      payout: 'EyeEm marketplace earnings',
      blog: 'EyeEm Magazine - Photography Tutorials, Tips and Interviews',
      release:
        'Give your permission for this photo to be sold commercially on EyeEm Market, the marketplace for authentic photography.',
      searchDefault:
        'Discover unique photos with EyeEm – Find curated visual collections with millions of royalty-free pictures from outstanding photographers.',
      searchQuery:
        'Discover unique %(query)s Photography with EyeEm – Find curated visual collections with millions of royalty-free pictures from outstanding photographers.',
      seachQueryPaginated:
        'Page %(page)s of %(query)s Photography on EyeEm – Find curated visual collections with millions of royalty-free pictures from outstanding photographers.',
      login: 'Here you can login to your account on EyeEm.',
      signup: 'Sign-up and get started on EyeEm',
      signupCreator:
        'Join a network of +8m creatives worldwide. Get hired for shoots and distribute your work.',
      signupBuyer:
        'Create a business account to license royalty-free photography and book photo shoots worldwide.',
    },
    title: {
      default: 'EyeEm',
      notFound: 'Not Found (404) | EyeEm',
      error: 'Error (%(statusCode)s) | EyeEm',
      contact: 'Contact Us | EyeEm',
      privacyDe: 'Datenschutz | EyeEm',
      tosDe: 'Nutzungsbedingungen | EyeEm',
      collectionIndex: 'Stock Pictures Photos & Images | EyeEm',
      privacy: 'Our Privacy | EyeEm',
      tos: 'Terms of Service | EyeEm',
      imprint: 'Imprint | EyeEm',
      licensing: 'Licensing | EyeEm',
      sellerForm: 'Selling Photos With EyeEm',
      missions: 'Community Photography Missions | EyeEm',
      press: 'Press, Media & PR with EyeEm',
      upload: 'Upload Images & Photos | EyeEm',
      jobs: 'Jobs & Careers at EyeEm',
      mlp: 'EyeEm: Authentic Stock Photos and Royalty-Free Images',
      payout: 'Earnings',
      modelRelease: 'Model Release Form',
      propertyRelease: 'Property Release Form',
      iosReleaseManagement: 'EyeEm Market',
      story: 'Our Story | EyeEm',
      search: '%(query)s pictures | Curated Photography on EyeEm',
      searchPaginated:
        'Page %(page)s of %(query)s pictures | Curated Photography on EyeEm',
      mission: '%(missionTitle)s brief from %(partnerName)s | EyeEm',
      missionPhotos: 'Entries of %(missionTitle)s from %(partnerName)s | EyeEm',
      missionPhotosPaginated:
        'Page %(page)s | %(missionTitle)s from %(partnerName)s | EyeEm',
      missionPhotographers: 'Current Photography Competitions on EyeEm',
      login: 'Login on EyeEm',
      signup: 'Signup on EyeEm',
      signupBuyer: 'Signup as Buyer on EyeEm',
      signupCreator: 'Signup as Creator on EyeEm',
      verifyEmail: 'Email verification | EyeEm',
    },
  },
  payout: {
    header: {
      title: 'Payouts',
    },
    overview: {
      partnerInfo: '%(partner)s via The Partner Collection',
      eyeemMarket: 'EyeEm Market',
      machineLearning: 'Portfolio of Selected Market Images',
      tabs: {
        ready: 'Pending',
        history: 'History',
      },
      noneReadyForPayout:
        'Hello! You have no earnings to pay out at the moment.',
      creditNote: 'Credit Note',
      payoutPending: {
        one: "🎉 Congrats, you've sold %(count)s image license!",
        other: "🎉 Congrats, you've sold %(count)s image licenses!",
      },
      photoDeleted: 'This image has been deleted',
      history: {
        subtitle: {
          sold: {
            zero: '%(count)s image licenses sold.',
            one: '%(count)s image license sold.',
            other: '%(count)s image licenses sold.',
          },
          status: {
            paid: {
              status: 'Transferred',
              payee: 'to %(payee)s',
              error: '',
            },
            processing: {
              status: 'Processing',
              payee: 'for %(payee)s',
              error:
                'You’ll receive a PayPal notification when the payout has been transferred.',
            },
            failed: {
              status: 'Failed',
              payee: 'for %(payee)s',
              error: 'Error',
            },
            pending: {
              status: 'Pending',
              payee: 'for %(payee)s',
            },
            requested: {
              status: 'Requested',
              payee: 'for %(payee)s',
              error:
                'You’ll receive a PayPal notification when the payout has been transferred.',
            },
            lapsed: {
              status: 'Lapsed',
              payee: 'for %(payee)s',
              error: 'You did not request your payout in time.',
            },
            unknown: {
              status: 'Payout error',
              payee: 'for %(payee)s',
              error:
                'Your payout has an unknown status. Please contact support@eyeem.com for help.',
            },
          },
        },
        payoutReceipt: 'Download Payout Receipt',
        expand: 'View images',
        noPayoutHistory:
          "You haven't made any payouts yet. When you do, they'll appear here.",
        explanation:
          'Requested payouts are processed by the end of the month. If your payout request was made fewer than 7 days before month end, your earnings will be received the following month.',
      },
    },
    actions: {
      title: 'Summary',
      eventPosition: 'seller_payout',
      payoutTeaser:
        'As soon as you make a sale, your earnings will be shown here.',
      earnings: 'License Revenue',
      period: 'Period',
      share: 'Revenue Share',
      total: 'Lifetime Earnings',
      pending: 'New Earnings',
      newSales: {
        zero: 'No New Sales',
        one: '%(count)s New Sale',
        other: '%(count)s New Sales',
      },
      payoutRequested: 'Payout requested',
      totalSales: 'Total Earnings: %(amount)s',
      minPayoutInfo:
        'You can payout your earnings when you reach $%(amount)s or more.',
      learnMore: 'Learn more',
      learnMoreUrl:
        'https://eyeem.zendesk.com/hc/en-us/articles/115001622085-I-ve-sold-a-photo-Now-what-',
      processing: 'Processing',
      readyToPayOut: {
        connectedAccount: 'Connected Account',
        changeAccount: 'Change account',
        payoutToPayPal: 'Payout\u00A0%(amount)s',
        noCredit: 'Nothing to pay out',
        payoutError:
          'You can payout once your new earnings reaches %(minThreshold)s or more. ',
      },
      paypal: {
        title: 'Paypal',
        notConnected: 'No Paypal account',
        connect: 'Connect',
        remove: 'Remove',
      },
      register: {
        firstName: 'Your first name',
        lastName: 'Your last name',
        email: 'Your PayPal email address',
        text: 'Please enter the email address and full name exactly as they appear in your PayPal account.',
        button: 'Next',
        dontHave: 'Don’t have PayPal? %(create)s',
        createAnAccount: 'Create an account',
        emailSent:
          'For security reasons, we need to verify your identity. An email with a verification link has been sent to: %(email)s. The link will expire in 30 minutes.',
      },
      requestPassword: {
        input: 'EyeEm Password',
        text: 'Please enter your EyeEm password',
        button: 'Next',
        forgot: 'Forgot your password?',
        wrongCredentials:
          "The password you entered doesn't seem to be valid. If you don't know your password, you can simply reset it using the link below.",
        marketSupport: {
          label: 'the FAQs',
          href: 'https://eyeem.zendesk.com/hc/en-us/articles/115001622085',
        },
      },
    },
  },
  questions: {
    title: 'Questions?',
    payouts: {
      label:
        "We're happy to help! Visit %(link)s for more information on payouts.",
      faqLink: 'https://eyeem.zendesk.com/hc/en-us/',
      eventName: 'access_seller_help',
    },
    checkout: {
      label:
        "We're happy to help! Visit %(link)s for more information on payment and more.",
      faqLink: 'https://eyeem.zendesk.com/hc/en-us/',
      eventName: 'access_buyer_help',
    },
    descriptionLink: 'our FAQs',
  },
  search: {
    label: 'Search',
    placeholder: {
      photo: 'Search for Photos',
      illustration: 'Search for Illustrations',
    },
    results: 'results',
    fullTextSearch: 'Search everywhere for',
    pressEnter: 'Press Enter to search',
    resultsHeadline: 'Unique Images of %(query)s',
    resultsHeadlineNoQuery: '%(number)s Unique Images',
    clearFilters: 'Clear',
    spellcheck: {
      suggest: 'Did you mean %(correction)s?',
      replace:
        'Showing results for %(correction)s. Search for %(query)s instead?',
    },
    landing: {
      title: 'Explore millions of images',
    },
    proDownloadsFilter: {
      allPhotos: 'All photos',
      newPhotos: {
        bcg: 'New BCG images',
        enterprise: 'New photos',
      },
      downloaded: {
        bcg: 'BCG Collection',
        enterprise: 'My Collection',
      },
    },
    labels: {
      abstractConcepts: 'Image style',
      ageGroups: 'Age groups',
      amountOfPeople: 'Number of people',
      collection: 'Collections',
      filters: 'Filters',
      formats: 'Format',
      grayscale: 'Color',
      locations: 'Location',
      marketScore: 'Market score',
      marketStatus: 'Market',
      minResolution: 'Minimum resolution',
      proDownloads: 'Download history',
      released: 'Releases',
      reviewedAfter: 'Date published',
      sort: 'Sort by',
      stock: 'Stock',
      uploadedAfter: 'Date uploaded',
      usage: 'Photo usage',
      userSignedUpAfter: 'Date of creator signup',
    },
    marketStatus: {
      available: 'Photos on EyeEm Market',
      includeEditorial: 'All market photos',
      editorial: 'Editorial',
      commercial: 'Commercial',
      notAvailable: 'Not on market',
    },
    formats: {
      any: 'All',
      square: 'Square',
      panorama: 'Panorama',
      landscape: 'Landscape',
      widescreen: 'Widescreen',
      portrait: 'Portrait',
    },
    minResolution: {
      any: 'Any resolution',
      three: '3 MP',
      nine: '9 MP',
      twelve: '12 MP',
      sixteen: '16 MP',
    },
    grayscale: {
      any: 'Any color',
      color: 'Color',
      blackAndWhite: 'Black and white',
    },
    amountOfPeople: {
      noPeople: 'No people',
      onePerson: 'One person',
      twoPeople: 'Two people',
      groupOfPeople: 'Group of people',
    },
    released: {
      model: 'Model released',
    },
    reviewedAfter: {
      any: 'Any date',
      sevenDays: 'Past 7 days',
      fourteenDays: 'Past 14 days',
      oneMonth: 'Past month',
      twoMonths: 'Past 2 months',
      sixMonths: 'Past 6 months',
      oneYear: 'Past year',
    },
    uploadedAfter: {
      any: 'Any date',
      sevenDays: 'Past 7 days',
      fourteenDays: 'Past 14 days',
      oneMonth: 'Past month',
      twoMonths: 'Past 2 months',
      sixMonths: 'Past 6 months',
      oneYear: 'Past year',
    },
    ageGroups: {
      baby: 'Baby',
      child: 'Child',
      teenager: 'Teenager',
      young_adult: 'Young Adult',
      mid_adult: 'Mid Adult',
      mature_adult: 'Mature Adult',
      senior: 'Senior',
    },
    abstractConcepts: {
      close_up: 'Close up',
      directly_above: 'Directly above',
      aerial_view: 'Aerial view',
      looking_at_camera: 'Portrait',
      diminishing_perspective: 'Diminishing perspective',
      blurred: 'Blurred',
      backgrounds: 'Backgrounds and pattern',
      copy_space: 'Copy space',
      silhouette: 'Silhouette',
    },
    replaceQuery: {
      replace: 'Replace',
      dontReplace: "Don't replace",
    },
    marketScore: {
      great: 'Great',
      poor: 'Poor',
      average: 'Average',
      good: 'Good',
      very_good: 'Very Good',
    },
    collections: {
      screenTitle: 'Quality',
      none: 'All Photos',
      mixed: 'All',
      premium: 'Premium · %(currency)s349',
      essential: 'Essential · %(currency)s99',
      premiumEnterprise: 'Premium',
      essentialEnterprise: 'Essential',
    },
    assetTypes: {
      mixed: 'All',
      photos: 'Photos',
      illustrations: 'Illustrations',
    },
    userSignedUpAfter: {
      any: 'Any date',
      sevenDays: 'Past 7 days',
      fourteenDays: 'Past 14 days',
      oneMonth: 'Past month',
      twoMonths: 'Past 2 months',
      sixMonths: 'Past 6 months',
      oneYear: 'Past year',
    },
    stock: {
      noStock: 'No Stock',
      flagged: 'Flagged',
      confirmed: 'Confirmed',
    },
    sort: {
      unsupported: 'Sort',
      relevant: 'Most Relevant',
      recent: 'Most Recent',
      aesthetics: 'Aesthetics',
      pa: {
        defaultLabel: 'Trained Visual Identity',
        bcg: 'BCG Visual Identity',
        booking: 'Travel & Nature',
        ebay: 'Retail',
        hdi: 'Finance',
        google: 'Urban',
        democonsulting: 'Technology',
        amana_lifestyle: 'Amana Lifestyle',
        amana_conceptual: 'Amana conceptual',
        amana_landscape: 'Amana Landscape',
        bcg_v2: 'BCG Visual Identity',
        ebay_v2: 'Ebay v2',
        sennheiser: 'Sennheiser Professional',
        sennheiser_v2: 'Sennheiser Professional',
        travel: 'Travel',
      },
    },
    collectionCurtain: {
      title: 'Browse our Collections',
      subline:
        "Your search doesn't have to end here. Explore our EyeEm Collections for thousands of high-quality images from a fresh generation of photographers.",
      collectionIndexLink: 'View all Collections',
    },
    hint: {
      alternativeTerms: {
        title: 'Try these search terms',
        terms: {
          nature: 'Nature',
          digital: 'Digital',
          teamwork: 'Teamwork',
          industry: 'Industry',
          architecture: 'Architecture',
          business: 'Business',
        },
      },
      missions: {
        title: 'Searching for something specific?',
        text: 'Missions let you reach out to millions of photographers to get the images you really want, created just for you.',
        linkText: 'Learn more',
      },
    },
    lightboxPreviewGrid: {
      illustrationTitle: 'Check out our illustration collections here!',
      photoTitle: 'Or check out our photo collections',
      illustrationSubTitle: 'Searchable illustrations are coming soon.',
      infoLabel: 'New!',
    },
    error: {
      compact: 'No results. Try modifying your filters.',
      default: 'We couldn’t find any results for your search.',
    },
    related: {
      label: 'related:',
    },
    evaluationInGrid: {
      label: 'Evaluate the results for "%(query)s"',
      eventName: 'search_check_send',
      eventLabel: {
        ok: 'ok',
        notOk: 'not ok',
      },
      eventPosition: 'search_grid',
    },
  },
  loginSignup: {
    placeholder: {
      nickname: 'Username',
      email: 'Email address',
      password: 'Password',
    },
    alreadyHave: 'Already have an account? Login',
    signupSubmit: 'Sign Up',
    signupSubmitFacebook: 'Sign up with Facebook',
  },
  directDownload: {
    downloadButton: {
      download: {
        label: 'Download',
        eventName: 'ecom_download',
      },
      reDownload: {
        label: 'Download again',
        eventName: 'ecom_download_redownload',
      },
      checkOutNow: {
        label: 'Check Out Now',
      },
      addToCart: {
        label: 'Add to cart',
        eventName: 'ecom_addToCart',
      },
      inCart: {
        label: 'Added to cart',
        eventName: 'ecom_addToCart',
      },
      updateLicense: {
        label: 'Update license',
        eventName: 'ecom_updateLicenseInCart',
      },
      contactSales: {
        label: 'Contact Sales',
      },
    },
    selectLicense: {
      modal: {
        headline: 'Choose a license',
        sublineLightboxBuyer:
          'Selecting license for %(count)s photos. You will be able to edit the licenses in your cart.',
        sublineLightboxEnterprise: 'Selecting license for %(count)s photos.',
        sublineExcludeEditorial:
          'Some of the pictures in your Lightbox are not for sale and will be excluded from your download.',
        nextButton: 'Next',
        submitButton: 'Download',
        eventPosition: 'direct_download_modal',
        contactSales: 'Contact Sales',
      },
    },
    error: {
      title: 'Something went wrong...',
    },
  },
  upgradeLicense: {
    upgradeButton: {
      upgrade: {
        label: 'Upgrade',
      },
    },
    selectLicense: {
      modal: {
        headline: 'Upgrade your license',
        subline: 'For higher resolution and expanded usage.',
        nextButton: 'Next',
        submitButton: 'Upgrade',
        contactSales: 'Contact Sales',
      },
    },
  },
  pview: {
    marketDetails: {
      inReview: {
        title: 'In Review',
        inReview:
          'Thank you for your patience, We are currently reviewing your illustration. This usually takes about 7 days, but in some instances can take longer.',
      },
      onSale: {
        title: 'On Sale',
        onSale:
          'Great news! Your image has been approved and is available for sale on the EyeEm Marketplace. Thank you for your contribution.',
      },
      notOnSale: {
        title: 'Not on Sale',
        imageSimilarity:
          'Your image review is complete and this image will not be for sale on the EyeEm Marketplace. While this image was deemed too similar to some other content that you submitted, we encourage you to continue to submit different work.',
        watermarks:
          'Your image review is complete and this image will not be for sale on the EyeEm Marketplace. The marketplace cannot accept images featuring watermarks or credit lines. If possible, please review, remove the watermark and resubmit.',
        lackOfMarketDemand:
          'Your image review is complete and this image will not be for sale on the EyeEm Marketplace. While this particular image was not approved due to lack of market demand, we encourage you to continue to submit different work.',
        termsOfService:
          'This image was not accepted for EyeEm Market due to the fact that it features some logos, trademarks or infringes on copyright and make it inadmissible. If possible, please review, remove the prohibited features and resubmit.',
      },
      comingSoon: 'This illustration will be available for purchase soon!',
      dealDepleted: 'Your team has run out of downloads.',
      disallowedPartnerSource:
        'Licensing images from partner agencies has been disabled for this account by request.',
      marketAvailabilityMessage: 'Available on EyeEm Market.',
      noAvailableDeal:
        "Your deal doesn't include our %(collectionType)s collection.",
      viewerRole: {
        headline:
          'Your account does not have permission to purchase this image. Ask an image buyer on your team to purchase it to make it available for download.',
      },
      noPermission: {
        headline:
          'Your account does not have permission to purchase this image. Ask an admin or image buyer in your team to purchase it for you.',
        redownload: {
          headline:
            'This image was already purchased by someone in your company. You can redownload it using the same license.',
        },
      },
      notForSale: {
        enterprise: {
          headline: 'Not available for sale.',
          subline:
            'This image is not part of the EyeEm Market and therefore not available for licensing. Please contact your account manager. We are happy to propose alternative photos that are currently available.',
        },
        buyer: {
          headline: 'Not available for sale.',
          subline:
            'This image is not part of the EyeEm Market and therefore not available for licensing. Please get in touch. We are happy to propose alternative images that are currently available.',
          button: 'Contact Us',
          editorial:
            'This image is not available for direct download. Please contact your account manager if you want to buy this image.',
        },
        default: {
          headline: 'Image currently not available for licensing.',
        },
      },
      purchased: {
        title: "You've already licensed this image.",
        text: 'Go to your Download History to download it again.',
        button: 'Download History',
      },
      addToMarket: {
        title: 'Sell this photo',
        text: "This photo isn't available for sale, yet. Earn money with this photo by adding it to Market now.",
        button: 'Add to Market',
      },
      joinAsSeller: {
        title: 'Want to sell this photo?',
        text: "Sell your work at leading photo agencies such as Getty Images. You'll make money every time and always keep your copyright.",
        button: 'Register to Sell',
      },
      onHold: {
        title: 'Verify your email address',
        text: 'This photo can only be made available for sale when we have a way of contacting you. We need this in order to pay you when you sell.',
        button: 'Resend verification email',
      },
      refused: {
        title: 'Photo not selected for Market',
        text: 'Unfortunately this photo was not selected to be sold on EyeEm Market. Questions? Check out %(faq)s.',
        faqLink: {
          label: 'the FAQs',
          href: 'https://eyeem.zendesk.com/hc/en-us/articles/115001621865',
        },
      },
      addToCart: {
        alternateCurrency: {
          label: {
            eu: 'Do you want to pay in euros? %(link)s',
            gb: 'Do you want to pay in pounds? %(link)s',
            link: 'Request an invoice',
          },
          modal: 'Request an invoice',
        },
      },
      enterprise: {
        title: {
          editorial: 'Editorial Use Only',
        },
        text: {
          editorial:
            'This photo is not available for direct download. Please contact your account manager if you want to buy this photo.',
        },
        depleted: {
          title: 'No downloads left',
          subline: 'Select a different license or get in touch with us.',
        },
        noDeal: {
          title: 'No Deal Available.',
          subline:
            'Please fill out the form. Our Sales team will contact you shortly.',
        },
      },
      releaseManagement: {
        releaseNamePlaceholder: {
          MODEL: 'Model %(number)s',
          PROPERTY: 'Property Owner %(number)s',
        },
        overflowMenu: {
          removeFromMarket: 'Remove from Market',
          editReleases: 'Add/remove releases',
        },
        marketStatus: {
          headlines: {
            illustrationUnderReview:
              'Your illustration will be considered by our curation team for the EyeEm Market.',
            onMarket: 'On Market%(tooltip)s',
            partner: 'Partner Collection%(tooltip)s',
            partnerSelected: 'Selected for Partner Collection%(tooltip)s',
            underReview: 'Under Review%(tooltip)s',
          },
          tooltips: {
            partner: {
              ok: {
                title: 'Partner Collection',
                body1: 'This photo is for sale on EyeEm Market.',
                body2:
                  'It is also available on Getty Images and other partners via the Partner Collection.',
                href: 'https://www.eyeem.com/blog/partner-collection',
                link: 'Read more about the Partner Collection',
              },
            },
            partnerSelected: {
              ok: {
                title: 'Selected for Partner Collection',
                body1: 'This photo is for sale on EyeEm Market.',
                body2:
                  'It was also selected for distribution to Getty Images and other partners via the Partner Collection.',
                href: 'https://www.eyeem.com/blog/partner-collection',
                link: 'Read more about the Partner Collection',
              },
              modelAndProperty: {
                title: 'Selected for Partner Collection',
                body1: 'This photo is for sale on EyeEm Market.',
                body2:
                  'It was also selected for the Partner Collection, but model and property releases need to be signed before it can be sold on parter sites.',
                href: 'https://eyeem.zendesk.com/hc/en-us/articles/115001619225',
                link: 'Read more about releases',
              },
              model: {
                title: 'Selected for Partner Collection',
                body1:
                  'This photo is for sale on EyeEm Market, but only with an editorial license.',
                body2:
                  'It was also selected for the Partner Collection, but model releases need to be signed before it can be sold on parter sites.',
                href: 'https://eyeem.zendesk.com/hc/en-us/articles/115001619225',
                link: 'Read more about model releases',
              },
              property: {
                title: 'Selected for Partner Collection',
                body1:
                  'This photo is for sale on EyeEm Market, but only with an editorial license.',
                body2:
                  'It was also selected for the Partner Collection, but property releases need to be signed before it can be sold on parter sites.',
                href: 'https://eyeem.zendesk.com/hc/en-us/articles/115001619225',
                link: 'Read more about property releases',
              },
            },
            onMarket: {
              ok: {
                title: 'On Market',
                body1: 'This photo is for sale on EyeEm Market.',
                href: 'https://eyeem.zendesk.com/hc/en-us/articles/115001621505',
                link: 'Read more about selling photos',
              },
              modelAndProperty: {
                title: 'On Market',
                body1: 'This photo is for sale on EyeEm Market.',
                href: 'https://eyeem.zendesk.com/hc/en-us/articles/115001621505',
                link: 'Read more about selling photos',
              },
              model: {
                title: 'On Market',
                body1:
                  'This photo is for sale on EyeEm Market, but only with an editorial license.',
                body2:
                  'In order to sell it with a full commercial license - and potentially make more money - please get permission from each identifiable person by asking them to sign a model release.',
                href: 'https://eyeem.zendesk.com/hc/en-us/articles/115001619225',
                link: 'Read more about model releases',
              },
              property: {
                title: 'On Market',
                body1:
                  'This photo is for sale on EyeEm Market, but only with an editorial license.',
                body2:
                  'In order to sell it with a full commercial license - and potentially make more money - please get permission from each property owner by asking them to sign a property release.',
                href: 'https://eyeem.zendesk.com/hc/en-us/articles/115001619225',
                link: 'Read more about property releases',
              },
            },
            underReview: {
              ok: {
                title: 'Under Review',
                body1:
                  'This image is currently under review. Our reviewing team will take a look at it before accepting it into EyeEm Market.',
                href: 'https://eyeem.zendesk.com/hc/en-us/articles/115001618805',
                link: 'Read more about the review process',
              },
              modelAndProperty: {
                title: 'Under Review',
                body1:
                  'This image is currently under review. Our reviewing team will take a look at it before accepting it into EyeEm Market.',
                body2:
                  'This image contains people, and designs, logos or private buildings. Get permission from each identifiable person and property owner by asking them to sign releases.',
                href: 'https://eyeem.zendesk.com/hc/en-us/articles/115001619225',
                link: 'Read more about releases',
              },
              model: {
                title: 'Under Review',
                body1:
                  'This image is currently under review. Our reviewing team will take a look at it before accepting it into EyeEm Market.',
                body2:
                  'This image contains people. In order to sell it with a full commercial license - and potentially make more money - please get permission from each identifiable person by asking them to sign a model release.',
                href: 'https://eyeem.zendesk.com/hc/en-us/articles/115001619225',
                link: 'Read more about model releases',
              },
              property: {
                title: 'Under Review',
                body1:
                  'This image is currently under review. Our reviewing team will take a look at it before accepting it into EyeEm Market.',
                body2:
                  'This image contains designs, logos or private buildings. In order to sell it with a full commercial license - and potentially make more money - get permission from each property owner by asking them to sign a property release.',
                href: 'https://eyeem.zendesk.com/hc/en-us/articles/115001619225',
                link: 'Read more about property releases',
              },
            },
          },
          editorialUseOk: 'Editorial use: Ok',
          commercialUse: {
            ok: 'Commercial use: Ok',
            modelAndProperty:
              'Commercial use: Needs model and property releases',
            model: 'Commercial use: Needs model releases',
            property: 'Commercial use: Needs property releases',
          },
        },
        releaseStatus: {
          undefined: 'Release needed',
          generatingFile: 'File is being generated',
          NEEDED: 'Release needed',
          REQUESTED: 'Release requested',
          CLEARED: 'Release signed',
          DENIED: 'Release declined',
        },
        removeRelease: 'Remove',
        askForRelease: {
          needed: 'Ask',
          upload: 'Upload',
          pending: 'Remind',
          askModal: {
            subline: {
              model: {
                intro: {
                  one: 'Your photo requires a signed model release to be sold commercially. Model releases are needed when a photo contains:',
                  other:
                    'Your photos require a signed model release to be sold commercially. Model releases are needed when a photo contains:',
                },
                first: 'Recognizable faces',
                second: 'Face silhouettes',
                third:
                  'Tattoos or other significant features that makes a person identifiable',
                link: {
                  text: 'Learn more here',
                  href: 'https://www.eyeem.com/blog/do-you-need-a-legal-photo-release',
                },
                instructions:
                  'Simply copy the release form link below and send it to the person in the photo via email or social channels.',
                upload: 'Got a PDF/JPEG release? Upload it here.',
              },
              property: {
                intro:
                  'Your photo requires a signed property release to be sold commercially. Property releases are needed when a photo contains:',
                first: 'Private property (e.g. offices, houses, stores)',
                second: 'Logos, trademarks or unique design elements',
                third: 'Artworks or other artistic creations',
                link: {
                  text: 'Learn more here',
                  href: 'https://www.eyeem.com/blog/do-you-need-a-legal-photo-release',
                },
                download: {
                  instructions:
                    'Download and fill out a %(link)s or upload an existing one (PDF/JPG).',
                  link: {
                    text: 'property release form',
                    href: 'https://dtpuu5koy2g08.cloudfront.net/market/EyeEm_property_release_en.pdf',
                  },
                },
              },
            },
            button: {
              copy: 'Copy link',
              copied: 'Copied!',
              eventName: 'release_request_copy',
              eventPosition: 'pv_stage',
            },
            pdfUpload: {
              label: 'Got a PDF/JPEG release?',
              link: 'Upload release',
            },
          },
          uploadModal: {
            title: {
              modelRelease: 'Model Release',
              propertyRelease: 'Property Release',
            },
            genderRadio: {
              label: {
                single: 'Select gender',
                multi: 'Select gender',
              },
              male: 'Male',
              female: 'Female',
              other: 'Other',
            },
            birthdaySelect: {
              label: {
                single: 'Enter date of birth',
                multi: 'Enter date of birth',
              },
            },
            pdfInput: {
              label: 'Attach release PDF/JPEG',
              button: 'Upload release',
              remove: 'Remove',
            },
            success: {
              headline: 'Your release was successfully submitted.',
            },
          },
        },
      },
    },
    releaseInfo: {
      availableCommercialy: 'Available for commercial use.',
      notForSale: 'Not available for sale.',
      releaseOnFile: '%(amount)s %(releaseType)s release(s) on file',
    },
    comment: {
      input: 'Write something nice...',
      count: {
        zero: 'Comments',
        one: '%(count)s Comment',
        other: '%(count)s Comments',
      },
      add: 'Add comment',
      delete: 'delete',
      replyTo: 'reply to',
      instructions: 'Press %(shift)s + %(enter)s to make a line-break',
    },
    like: {
      zero: 'Likes',
      one: '%(count)s Like',
      other: '%(count)s Likes',
    },
    showAllLikers: 'Show all',
    similarPhotos: {
      headline: 'Similar Photos',
      eventName: 'access_asset',
      eventPosition: 'similar_photos',
    },
    keywordSearchLinks: {
      eyeemvision: 'EyeEmVision Keywords (admin only)',
      getty: 'Getty Keywords (admin only)',
    },
  },
  share: {
    flyoutHeader: 'Share',
    photo: {
      tweet: 'Check out this photo by %(user)s at',
      default: 'Photo by %(user)s on EyeEm',
    },
    album: {
      tweet: 'Check out photos in the album "%(album)s" at',
      default: 'View photos in the album "%(album)s" on EyeEm',
    },
    profile: {
      tweet: "Discover %(user)s's photos at",
      default: "View %(user)s's photos on EyeEm",
    },
    blog: {
      tweet: 'Check out this article',
      default: 'View this article %(articleUrl)s via @EyeEm',
    },
    // note: messages are also set by og meta tags. facebook,gplus
    // see the 'meta' property in this file
  },
  tooltip: {
    user: {
      likedPhotos: 'Only visible to you',
    },
  },
  modal: {
    assetPosted: {
      // the zero property will not be shown as we don't show a modal when no images have been uploaded
      // it is added in the translations however as counterpart requires it to interpolate the plural translations
      title: {
        zero: `Your %(assetType)s has been posted!`,
        one: `Your %(assetType)s has been posted!`,
        other: `Your %(assetType)s have been posted!`,
      },

      assetType: {
        illustrations: {
          zero: 'illustration',
          one: 'illustration',
          other: 'illustrations',
        },
        unknown: {
          zero: 'image',
          one: 'image',
          other: 'images',
        },
      },
      cta: { goToProfile: 'Go to profile', uploadMore: 'Upload more' },
    },
    // TODO: Using the question block is deprecated and should be refactored.
    // Rather create a separate block with all the copy for each new modal you create
    // (as done for leadGen and deleteUser, for example).
    question: {
      confirmNavigation: 'Do you want to leave without finishing?',
      deleteComment: 'Do you really want to delete this comment?',
      flagPhoto: 'Why do you want to report this photo?',
      removeTag: 'Why are you removing this tag?',
      deletePhoto: 'Do you really want to delete your photo?',
      deletePhotoMarket: 'Do you really want to delete this photo?',
      photosPosted: {
        success: {
          one: 'Your photo has been posted!',
          other: "You've successfully posted %(count)s photos!",
        },
        fail: {
          zero: "Unfortunately we couldn't post any of your photos",
          one: 'Only one of your photos has been successfully posted',
          other: 'Only %(count)s of your photos have been posted',
        },
      },
      uploadErrors: {
        maxFilesExceeded: 'You reached your limit of %(maxFiles)s images',
        fileError: 'We skipped something',
        generalRequestError: 'Something went wrong while uploading',
      },
    },
    info: {
      confirmNavigation:
        'You have unsaved changes which you will lose if you switch to another section. Are you sure you want to continue?',
      deletePhoto: "Watch out! You won't be able to undo this.",
      deletePhotoMarket:
        'This image will also be removed from sale on EyeEm Market and any subsequent partner collections. You can no longer profit from it.',
      photosPosted: {
        success:
          'Only the first %(numberOfPhotosInFollowingFeed)s images you post will appear in the Following feed. They will all be visible on your profile.',
        fail: {
          one: 'Try again to post the remaining photo',
          other: 'Try again to post the remaining %(count)s photos',
        },
      },
      uploadErrors: {
        maxFilesExceeded:
          'Right now you can only upload %(maxFiles)s images at once. You can upload as many batches as you like though.',
        filesUnsupported:
          "The following files don't appear to be JPGs and were therefore not uploaded:",
        filesTooBig: 'The following files were too big (max %(maxSize)smb):',
        andMore: '+ %(count)s more',
        generalRequestError:
          "Our server couldn't process your upload. Please try again.",
      },
    },
    radioButtons: {
      removeTag: {
        notInPhoto: "I'm not in the photo",
        dontLike: "I don't like this photo of me",
        spam: 'This is spam',
        other: 'Other',
      },
      flagPhoto: {
        nudity: 'Nudity',
        copyright: 'Copyright Infringement',
        tos: 'Terms of Service violation',
      },
    },
    cancel: {
      default: 'Cancel',
      done: 'OK',
      confirmNavigation: 'Stay on this page',
      photosPosted: {
        success: 'Upload more',
        fail: 'Try again',
      },
    },
    confirm: {
      confirmNavigation: 'Leave',
      deleteComment: 'Delete comment',
      flagPhoto: 'Report for review',
      removeTag: 'Remove my tag',
      deletePhoto: 'Delete my photo',
      deletePhotoMarket: 'Delete my photo',
      info: 'Confirm',
      license: 'Tell me more',
      photosPosted: 'Go to profile',
      save: 'Save',
    },
    message: {
      flagPhoto:
        'Your request has been noted. Thanks for keeping EyeEm enjoyable!',
      removeTag:
        "You'll no longer be tagged in this photo. But it will still live in the EyeEm archives.",
    },
    success: {
      default: 'OK',
      gotIt: 'Got it',
    },
    umboTOS: {
      title: 'Terms of Service Update',
      description: 'We will update our Terms of Service on March 1st.',
      accept: 'Accept Updates',
      readTerms: 'Read Terms',
      href: 'https://www.eyeem.com/terms/photographers',
    },
    cantGetReleasesConfirmation: {
      headline: 'This photo will be removed from the list',
      subline:
        'By clicking "I can\'t get releases", you will remove this photo from the list. You can always add releases later by accessing the photo again from your profile.',
      cantGet: "I can't get releases",
    },
    resendVerificationEmail: {
      headline: 'Check your mailbox!',
      subline:
        "Please confirm your email address to start selling. We've sent a verification email to:",
      notYourEmail: 'Not your email address?',
      changeIt: 'Change it',
    },
    clearDownloadRequirements: {
      headline: 'Please provide the info below to download.',
      default: {
        subline:
          '%(customerName)s requires the following information before you can download.',
        genericCustomer: 'Your company',
      },
      bcg: {
        subline:
          'BCG requires a charge code to track licenses. For questions and exclusive licensing options contact %(email)s.',
        email: 'bcg@eyeem.com',
        recentChargeCodeHint: 'Recent code: %(code)s',
      },
      submit: 'Download now',
      back: 'Back',
      defaultSelectTitle: 'Choose an option',
    },
    removeFromMarket: {
      headline: 'Do you really want to remove this photo from sale?',
      subline:
        'This photo will be removed from Market and will no longer be available for sale. You cannot undo this!',
      cancel: 'Cancel',
      confirm: 'Remove photo',
      confirmationMessage: 'Your photo was successfully removed from Market',
    },
    editReleases: {
      eventPosition: 'modal_editReleases',
      headline: 'Add or remove model and property releases',
      done: {
        eventName: 'close_modal_editReleases',
        label: 'Done',
      },
      addRelease: {
        MODEL: {
          pending: 'Adding...',
          add: 'Add Model',
        },
        PROPERTY: {
          pending: 'Adding...',
          add: 'Add Property Owner',
        },
      },
    },
    passwordModal: {
      title: 'Confirm password change',
      subline: 'Please enter your current password.',
      placeholder: 'Current password',
      confirm: 'Confirm',
    },
    deleteUser: {
      title: 'Are you sure you want to delete your account forever?',
      body: 'Once you delete it, there will be no way to restore your photos. Please enter your password below to confirm:',
      bodyForFBUsers:
        'Once you delete it, there will be no way to restore your photos.',
      placeholderPassword: 'Password for %(nickname)s',
      cancel: 'Cancel',
      confirm: 'Delete my account',
      wrongPassword:
        "The password you entered doesn't seem to be valid. Please set a new one on your account settings page.",
    },
    changeMarketEmail: {
      title: 'Change your email address',
      subline:
        'Enter a new email address below and click "Save and send" to send a new verification email.',
      placeholderEmail: 'Email address',
      confirm: 'Save and send',
    },
    confirmPackDownload: {
      title: 'Confirm Download',
      subline: 'Use 1 Download to license this Image?',
      detailsHeader: 'PLAN SUMMARY',
      packTitle: 'Title',
      type: 'License',
      downlaodsLeft: 'Downloads',
      confirm: 'Confirm',
      back: 'Back',
    },
    packDownloadConfirmation: {
      title: 'Your asset is ready',
      continue: 'Continue',
      reDownload: 'Redownload',
    },
    contact: {
      eventPosition: 'contact_modal',
      submit: {
        label: 'Send',
        eventName: 'contact_form_send',
      },
      statusCodeMessage: {
        409: {
          headline: 'Couldn´t buy this photo.',
          subline:
            'This picture has already been purchased with an exclusivity flag from your company and can not be purchased by another user. Please contact support if you have any questions.',
        },
        402: {
          headline: 'Photo not available.',
          subline:
            'This photo is not available as part of your current package. Please contact our team, we are happy to help',
        },
      },
      directDownloadError: {
        errorMessage:
          'The image was licensed successfully but something went wrong while trying to generate your download. Try again by clicking redownload. Please contact our sales team if the problem persists.',
        title: 'Something went wrong trying to generate your download.',
      },
      noMessage: '(no message)',
      selectValues: {
        position: {
          __selectTitle__: 'Which describes you best? *',
          inHouse: 'In-house creative',
          agency: 'Agency creative',
          artBuyer: 'Art buyer',
          photographer: 'Photographer',
          other: 'None of the above',
        },
        usage: {
          __selectTitle__: 'How are you using the images? *',
          socialMedia: 'Social media',
          webAds: 'Web display ads',
          printAds: 'Print or out-of-home ads',
          editorial: 'Editorial',
        },
        companySize: {
          __selectTitle__: 'Company size *',
          small: '1-10',
          medium: '11-50',
          large: '51-250',
          xlarge: '250+',
        },
      },
      cart: {
        subject: 'Request to buy photos (non-Market country)',
        title: 'Please contact us to proceed with your checkout',
        subline:
          'Self-checkout in this country is currently not available. Contact us to purchase the photos you added to your cart.',
        placeholderName: 'Name',
        placeholderCompanyName: 'Company name',
        placeholderEmail: 'Email address',
        placeholderMessage: 'Your message',
        placeholderStreet: 'Street',
        placeholderZip: 'Zip',
        placeholderCity: 'City',
        placeholderCountry: 'Country',
        confirmationMessage:
          'Thanks! A member of our team will get back to you.',
      },
      long: {
        subject: 'EyeEm Market Request',
        placeholderName: 'Your name *',
        placeholderCompanyName: 'Company name *',
        placeholderEmail: 'Email address *',
        bottomLine: "You'll also get our bi-weekly visual insights.",
        confirmationMessage:
          "Thanks for getting in touch! You'll receive a response from our team shortly.",
      },
      short: {
        subject: 'EyeEm Market Request',
        placeholderName: 'Your name *',
        placeholderCompanyName: 'Company name *',
        placeholderEmail: 'Email address *',
        confirmationMessage:
          "Thanks for getting in touch! You'll receive a response from our team shortly.",
      },
      support: {
        subject: 'Support Request',
        title: 'Get in touch',
        subline:
          'Your message will be sent directly to our support team, who will be happy to help you out with any questions you have.',
        placeholderName: 'Your name *',
        placeholderEmail: 'Email address *',
        placeholderMessage: 'What can we help with?',
        confirmationMessage:
          "Thanks for getting in touch! You'll receive a response from our team shortly.",
      },
      supportPayout: {
        subject: 'Change Paypal Account',
        title: 'Change Paypal Account',
        subline:
          'As a security precaution, please fill out the following form containing your full name and email. We will then promptly reset your PayPal account.',
        placeholderName: 'Your name *',
        placeholderEmail: 'Email address *',
        placeholderMessage: 'What can we help with?',
        confirmationMessage:
          "Thanks for getting in touch! You'll receive a response from our team shortly.",
      },
      jobs: {
        subject: 'Speculative contact',
        title: "We're looking for you",
        placeholderName: 'Your name *',
        placeholderEmail: 'Email address *',
        placeholderMessage: 'How do you see us working together?',
        confirmationMessage:
          "Thanks for getting in touch! You'll receive a response from our team shortly.",
      },
      market: {
        title: 'Get in touch',
        subline: 'To: market@eyeem.com',
      },
      curations: {
        title: 'Request a Free Curation',
      },
      partnerships: {
        title: "Let's work together",
      },
      missions: {
        subject: 'Request to buy photos (non-Market country)',
        title: 'Please contact us to proceed with your checkout',
        subline:
          'Self-checkout in this country is currently not available. Contact us to purchase the photos you added to your cart.',
        placeholderEmail: 'Email address *',
        placeholderMessage: 'Your message',
        confirmationMessage:
          'Thanks! A member of our team will get back to you.',
      },
    },
    contactUsToRemove: {
      title: 'Contact us to remove this photo from sale',
      subline:
        'This photo is for sale on EyeEm and partner sites. Please email us at support@eyeem.com if you would like to stop selling it.',
    },
    signUp: {
      like: {
        headline: 'Join EyeEm to like that photo',
      },
      comment: {
        headline: 'Join EyeEm to comment on that photo',
      },
      follow: {
        headline: 'Join EyeEm to follow this photographer',
      },
    },
  },
  photographerBox: {
    aboutPhotographer: 'About the photographer',
    follows: 'Follows You',
    photoBy: 'By',
  },
  news: {
    page: {
      camera: {
        text: "Welcome to EyeEm! Photography starts here. It's time to upload your first photo.",
        link: 'Select photos',
      },
    },
    user: {
      follow: '%(user)s is now following you.',
      join: '%(user)s just joined EyeEm. %(follow)s',
      fbFriend: '%(user)s just joined EyeEm. %(follow)s',
      twFriend: '%(user)s just joined EyeEm. %(follow)s',
      followThem: 'Say hi. Follow them!',
    },
    album: {
      albumInvite: '%(user)s invited you to check out the album %(album)s.',
    },
    photo: {
      albumContributor: '%(user)s just added a new photo to %(album)s.',
      taggedPerson: '%(user)s tagged you in a photo.',
      like: '%(user)s likes your photo.',
      comment: '%(user)s commented on your photo: %(comment)s %(replyLink)s',
      commentMention:
        '%(user)s mentioned you in a comment: %(comment)s %(replyLink)s',
      reply: 'Reply',
      aggregatedComment:
        '%(user)s commented on %(photoCount)s of your photos: %(photoList)s',
      aggregatedSoldMultiplePhotos:
        'Congratulations, you sold %(photoCount)s images! Check your earnings now %(photoList)s',
      aggregatedLikeMultiplePhotos:
        '%(user)s liked %(photoCount)s of your photos: %(photoList)s',
      photoSold: 'Congratulations, you sold an image! Check your earnings now',
      photoSoldGetty: {
        title: 'EyeEm Market: ',
        body: {
          one: 'Congratulations, you sold an image!',
          other: 'Congratulations, you sold %(count)s images!',
        },
        link: 'Check your earnings now',
      },
    },
    photoAggregated: {
      like: {
        multipleUsers: '%(userList)s liked your photo.',
        oneMore: 'other liked your photo',
        muchMore: ' %(likersCount)s others liked your photo',
      },
      comment: {
        multipleUsers: '%(userList)s commented on your photo.',
        oneMore: 'other commented on your photo',
        muchMore: ' %(likersCount)s others commented on your photo',
      },
    },
    mission: {
      link: 'View the album!',
    },
    market: {
      getty: {
        pending:
          'Your photos have been selected for The EyeEm Collection at Getty Images.',
        approveForSale: 'Approve for sale now',
      },
      payoutEarnings: 'Pay out earnings',
    },
  },
  flag: {
    link: 'Report',
    modal: {
      title: 'Do you want to report this photo?',
    },
  },
  delete: {
    // TODO: why is this on top level?
    link: 'Delete',
    modal: {
      title: 'Do you really want to delete?',
      paragraph: {
        onSale:
          "It will be removed permanently from your account. If it's part of the Partner Collection, it will also be taken down from all partner sites. You cannot undo this!",
        notOnSale:
          'It will be removed permanently from your account. You cannot undo this!',
      },
      buttons: {
        cancel: 'Cancel',
        delete: 'Delete',
      },
      success: 'Deletion was successful.',
      error: {
        generic:
          "This image can't be deleted right now. Please get in touch with us at support@eyeem.com if you would like to remove it.",
        70001:
          'Your photo is part of a partner collection. Please get in touch with us at support@eyeem.com if you would like to delete it.',
        70002:
          'This photo has been sold in the past. Please get in touch with us at support@eyeem.com if you would like to delete it.',
      },
    },
  },
  edit: {
    link: 'Edit',
    modal: {
      done: 'Save',
      cancel: 'Cancel',
      error: "This photo can't be edited right now. Please try again later!",
    },
  },
  userlist: {
    editProfile: 'Edit Profile',
    photos: {
      zero: 'Photos',
      one: 'Photo',
      other: 'Photos',
    },
    followers: {
      zero: 'Followers',
      one: 'Follower',
      other: 'Followers',
    },
    following: {
      zero: 'Following',
      one: 'Following',
      other: 'Following',
    },
  },

  album: {
    album: 'Album',
    empty: {
      blacklisted: {
        icon: 'photos',
        headline: 'This album may contain sensitive content.',
      },
      photos: {
        icon: 'photos',
        headline: "This album doesn't have any photos in it, yet.",
        subline: 'Why not add one?',
        button: {
          label: 'Upload',
          href: '/upload/photos',
        },
      },
      contributors: {
        icon: 'people',
        headline: 'No one has added photos to this album, yet.',
        subline: 'Why not be the first?',
        button: {
          label: 'Upload',
          href: '/upload/photos',
        },
      },
    },
    photos: {
      zero: 'Photos',
      one: '%(abbreviatedCount)s Photo',
      other: '%(abbreviatedCount)s Photos',
    },
    contributors: {
      zero: 'Photographers',
      one: '%(abbreviatedCount)s Photographer',
      other: '%(abbreviatedCount)s Photographers',
    },
    followButton: {
      label: {
        follow: 'Follow',
        following: 'Following',
      },
      title: {
        follow: 'Follow album',
        following: 'Following album',
      },
    },
  },
  blog: {
    post: {
      header: {
        subline: 'By %(authorName)s',
        readingTime: ' - %(min)s min read',
      },
      recommendedArticles: 'Up next:',
    },
  },
  footer: {
    eventPosition: 'footer',

    columns: {
      products: {
        headline: 'For Brands',
        items: [
          {
            title: 'Image Licensing',
            href: '/licensing',
          },
          {
            title: 'Pricing & License Types',
            href: '/market/pricing',
          },
          {
            title: 'Need help?',
            href: 'https://eyeem.zendesk.com/hc/en-us/categories/360001243737-FAQ-Image-Buyers',
          },
        ],
      },
      photographers: {
        headline: 'For Contributors',
        items: [
          {
            title: 'Join as a contributor',
            href: '/signup',
          },
          {
            title: 'Sell your work',
            href: '/signup/creator',
          },
          {
            title: 'Ongoing Missions',
            href: '/missions/photographers',
          },
          {
            title: 'EyeEm Awards',
            href: '/awards',
          },
          {
            title: 'Need help?',
            href: 'https://eyeem.zendesk.com/hc/',
          },
        ],
      },
      projects: {
        headline: 'Photography culture',
        items: [
          {
            title: 'EyeEm Magazine',
            href: '/magazine',
          },
          {
            title: 'Visual Trends',
            href: '/visualtrends',
          },
        ],
      },
      eyeem: {
        headline: 'About',
        items: [
          {
            title: 'Our story',
            href: '/story',
          },
          {
            title: 'Work with us',
            href: '/jobs',
          },
          {
            title: 'Press',
            href: '/press',
          },
          {
            title: 'FAQ',
            href: 'https://eyeem.zendesk.com/hc/en-us/categories/115000290289-Frequently-Asked-Questions',
            external: true,
          },
        ],
      },
    },
    misc: {
      headline: '© %(year)s EyeEm Mobile GmbH.',
      items: [
        {
          title: 'Privacy',
          href: '/privacy',
        },
        {
          title: 'Terms of Service',
          href: '/tos',
        },
        {
          title: 'Imprint',
          href: '/imprint',
        },
      ],
    },
  },
  friendsFeed: {
    empty: {
      icon: 'people',
      headline: 'Start following people to discover beautiful photos.',
      subline:
        'We showcase the best photographers on EyeEm, every week. Discover them on our blog!',
      button: {
        label: 'Go to Blog',
        href: '/magazine',
      },
    },
  },
  user: {
    empty: {
      own: {
        photos: {
          icon: 'upload',
          headline: "You haven't uploaded anything yet.",
          subline: 'Join millions of creatives and share your work on EyeEm.',
          button: {
            label: 'Upload',
            href: '/upload/photos',
          },
        },
        illustrations: {
          button: {
            headline: "You haven't uploaded anything yet.",
            subline: 'Join millions of creatives and share your work on EyeEm.',
            label: 'Upload',
            href: '/upload/illustrations',
          },
          headline: "You haven't uploaded anything yet.",
          icon: 'upload',
          subline: 'Join millions of creatives and share your work on EyeEm.',
        },
        marketPhotos: {
          seller: {
            icon: 'market',
            headline: 'Brands want to buy your work. Get selling now!',
            subline:
              'You’ll make money every time you sell and always keep your copyright. %(link)s',
            link: {
              label: 'See what sells',
              href: '/a/12853871',
            },
            button: {
              label: 'Upload',
              href: '/upload/photos',
            },
          },
          nonSeller: {
            icon: 'market',
            headline: 'Brands want to buy your work. Get selling now!',
            subline:
              'You’ll make money every time you sell and always keep your copyright. %(link)s',
            link: {
              label: 'See what sells',
              href: '/a/12853871',
            },
            button: {
              label: 'Sell your work',
              href: '/market/sell/start',
            },
          },
        },
        partnerPhotos: {
          icon: 'partner',
          headline:
            'Our curators handpick photos to distribute to Getty Images and other partners. Yours could be next!',
          button: {
            label: 'Learn how',
            href: '/blog/partner-collection',
          },
        },
        releasesNeededPhotos: {
          icon: 'releases',
          headline: 'All Releases cleared',
          subline: "Once more Releases are needed, you'll see them here",
          button: {
            label: 'Back to Profile',
            href: '/u/%(resourceId)s',
          },
        },
        followers: {
          icon: 'people',
          headline: "You don't have any followers, yet.",
          subline:
            'Try uploading more of your best shots to show off your work!',
          button: {
            label: 'Upload',
            href: '/upload/photos',
          },
        },
        followings: {
          icon: 'people',
          headline: 'Start following people to discover beautiful photos.',
          subline:
            'Or get inspired by the global EyeEm community over on our blog.',
          button: {
            label: 'Read more',
            href: '/magazine',
          },
        },
        likedPhotos: {
          icon: 'photos',
          headline: "You haven't liked any photos yet.",
          subline:
            'We showcase the best photos on EyeEm, every week. Discover them on our blog!',
          button: {
            label: 'Read more',
            href: '/magazine',
          },
        },
      },
      foreign: {
        photos: {
          icon: 'photos',
          headline: "%(name)s hasn't uploaded any photos yet.",
          subline: "Once %(name)s has uploaded photos, you'll see them here.",
        },
        illustrations: {
          headline: "%(name)s hasn't uploaded any illustrations yet.",
          icon: 'photos',
          subline:
            "Once %(name)s has uploaded illlustrations, you'll see them here.",
        },
        marketPhotos: {
          icon: 'market',
          headline: "%(name)s doesn't have any photos for sale, yet.",
          subline: "Once %(name)s has photos to sell, you'll find them here.",
        },
        followers: {
          icon: 'people',
          headline: "%(name)s doesn't have any followers, yet.",
          subline: "Once %(name)s has followers, you'll see them here.",
        },
        followings: {
          icon: 'people',
          headline: "%(name)s isn't following anyone, yet.",
          subline: "Once %(name)s is following people, you'll see them here.",
        },
        likedPhotos: {
          icon: 'photos',
          headline: "%(name)s hasn't liked any photos, yet.",
          subline: "Once %(name)s has liked photos, you'll see them here.",
        },
      },
    },
    filterItems: {
      photos: 'All photos',
      market: 'Market',
      partner: 'Partner',
    },
    photos: {
      zero: 'Photos',
      one: '%(abbreviatedCount)s Photo',
      other: '%(abbreviatedCount)s Photos',
    },
    illustrations: {
      one: '%(abbreviatedCount)s Illustration',
      other: '%(abbreviatedCount)s Illustrations',
      zero: 'Illustrations',
    },
    followers: {
      zero: 'Followers',
      one: '%(abbreviatedCount)s Follower',
      other: '%(abbreviatedCount)s Followers',
    },
    followings: {
      zero: 'Following',
      one: '%(abbreviatedCount)s Following',
      other: '%(abbreviatedCount)s Following',
    },
    likedPhotos: {
      zero: 'Liked Photos',
      one: '%(abbreviatedCount)s Liked Photo',
      other: '%(abbreviatedCount)s Liked Photos',
    },
    market: {
      zero: 'Market',
      one: '%(abbreviatedCount)s Market',
      other: '%(abbreviatedCount)s Market',
    },
    partner: {
      zero: 'Partner',
      one: '%(abbreviatedCount)s Partner',
      other: '%(abbreviatedCount)s Partner',
    },
    earnings: 'Earnings',
    joinMarket: 'Join Market',
    followsYou: 'Follows You',
    followButton: {
      follow: 'Follow',
      following: 'Following',
    },
  },
  error: {
    restricted: 'This profile is restricted.',
    blocked: "You've blocked this person.",
    notFound: {
      legacyReleaseForm:
        'The link to this release form is outdated. Please ask the photographer for the latest one.',
      album: "Whoops! We can't find the album you're looking for...",
      collection: "Whoops! We can't find the collection you're looking for...",
      user: "Whoops! We can't find the user you're looking for...",
      photo: "Whoops! We can't find the image you're looking for...",
      release: "Whoops! We can't find the release you're looking for...",
      other: "Whoops! We can't find the page you're looking for...",
      mission: "Whoops! We can't find the Mission you're looking for...",
      lightbox: "Whoops! We can't find the Lightbox you're looking for...",
      blogPost: "Whoops! We can't find the blog post you're looking for...",
      backtoeyeem: 'Back to EyeEm',
      credits: 'By %(photographer)s',
      contactSupport:
        'Or %(supportLink)s if you suspect something weird is happening.',
      supportLink: 'contact support',
      supportHref: 'mailto:support@eyeem.com',
    },
    formLoadError:
      'There was a problem loading the contact form. Please try again later.',
  },
  licenses: {
    noPremiumAvailable: 'Your current deal does not include premium photos.',
    noLicensesAvailable:
      'Your current deal has no downloads left for the available licenses on this photo.',
    contactSales: 'Contact Sales',
    learn: 'Learn more',
    licensed: 'Licensed',
    remaining: '%(remaining)s of %(total)s downloads left',
    remainingDownloads: {
      counters: '%(remaining)s of %(total)s',
      label: 'downloads left',
    },
    needMore: 'Need more usage rights or something tailored to your budget?',
    requestACustomLicense: 'Get a customized deal',
    requestCustomLicense: 'Get a customized deal',
    unlimitedRemaining: 'Unlimited downloads',
    learnMoreLink: '/market/licensing',
    dimensions:
      '%(width)s x %(height)spx / %(cmWidth)s x %(cmHeight)scm @%(dpi)sdpi',
    packs: {
      essential: 'Essential Pack',
      premium: 'Premium Pack',
    },
    deprecatedTypes: {
      1: {
        title: 'Standard License',
      },
      2: {
        title: 'Extended License',
      },
      3: {
        title: 'Editorial License',
        body: 'For unlimited editorial use i.e. when illustrating events that are newsworthy or of public interest in all media except TV/video.',
        confirm: 'Tell me more',
        cancel: 'Got it!',
      },
      4: {
        title: 'Social License',
        body: 'For unlimited commercial use on social media networks and platforms. Model and property releases provided.',
        confirm: 'Tell me more',
        cancel: 'Got it!',
      },
      5: {
        title: 'Web License',
        body: 'For unlimited commercial use on web as well as in print publications with a print run of less than 5,000. Model and property releases provided.',
        confirm: 'Tell me more',
        cancel: 'Got it!',
      },
      6: {
        title: 'Full License',
        body: 'For editorial and commercial use, including advertising in all media (e.g. web, print, TV). Also for product packaging and incorporating into print or electronic products for resale or distribution. Model and property releases provided.',
        confirm: 'Tell me more',
        cancel: 'Got it!',
      },
      7: {
        title: 'Standard License',
        body: 'Our Standard License allows you to use your image for commercial purposes, incl. web (websites, social media, digital ads) and print (up to 5000 copies).',
        confirm: 'Tell me more',
        cancel: 'Got it!',
      },
      8: {
        title: 'Extended License',
        body: 'Our Extended License allows you to use your image for commercial purposes across all media, incl. TV and video clips, unlimited print publications and as part of products or its packaging.',
        confirm: 'Tell me more',
        cancel: 'Got it!',
      },
      20: {
        title: 'Basic License',
      },
      21: {
        title: 'Custom License',
      },
      partner: {
        title: 'Partner License',
      },
      machineLearning: {
        title: 'Machine Learning Training',
      },
    },
    types: {
      0: {
        title: 'Hi-Res Preview',
        body: 'For internal and layouting use only. This is not a purchased license. If you chose to use this image, you will need to purchase a paid license.',
      },
      1: {
        title: 'Social License',
        body: 'For unlimited commercial use on social media networks and platforms. Model and property releases provided.',
        confirm: 'Tell me more',
        cancel: 'Got it!',
      },
      2: {
        title: 'Web License',
        body: 'For unlimited commercial use on web as well as in print publications with a print run of less than 5,000. Model and property releases provided.',
        confirm: 'Tell me more',
        cancel: 'Got it!',
      },
      3: {
        title: 'Full License',
        body: 'For editorial and commercial use, including advertising in all media (e.g. web, print, TV). Also for product packaging and incorporating into print or electronic products for resale or distribution. Model and property releases provided.',
        confirm: 'Tell me more',
        cancel: 'Got it!',
      },
      4: {
        title: 'PREMIUM Social License',
        body: 'PREMIUM For unlimited commercial use on social media networks and platforms. Model and property releases provided.',
        confirm: 'Tell me more',
        cancel: 'Got it!',
      },
      5: {
        title: 'PREMIUM Web License',
        body: 'PREMIUM For unlimited commercial use on web as well as in print publications with a print run of less than 5,000. Model and property releases provided.',
        confirm: 'Tell me more',
        cancel: 'Got it!',
      },
      6: {
        title: 'PREMIUM Full License',
        body: 'PREMIUM For editorial and commercial use, including advertising in all media (e.g. web, print, TV). Also for product packaging and incorporating into print or electronic products for resale or distribution. Model and property releases provided.',
        confirm: 'Tell me more',
        cancel: 'Got it!',
      },
      7: {
        title: 'Standard License',
        body: 'For commercial use in web and print (up to 5000 copies).',
      },
      8: {
        title: 'Extended License',
        body: 'For commercial use across all media and unlimited print publications.',
      },
      20: {
        title: 'Basic License',
        body: 'All Basic licenses are royalty-free and include commercial use across all media and 500,000 print publications.',
      },
      21: {
        title: 'Custom License',
        body: '',
      },
      partner: {
        title: 'Partner License',
      },
    },
    creditText: 'by %(creator)s / EyeEm',
    copyrightNotice:
      'Copyright: %(creator)s / EyeEm Mobile GmbH, ALL RIGHTS RESERVED',
  },
  cart: {
    cartTitle: 'Cart',
    savedForLater: 'Saved for later',
    chatSupport: 'Chat support',
    contactSupport: 'Support',
    continue: 'Back',
    eventName: 'ecom_shopping',
    eventPosition: 'cart',

    undo: {
      notice: 'Your item has been removed.',
      action: 'Undo',
    },
  },
  checkout: {
    checkoutTitle: 'Checkout',
    downloadPreview: 'Download Preview',
    discountCode: {
      headline: 'Discount code',
      error: {
        invalid: 'Discount code %(code)s cannot be used.',
        reused: 'Discount code %(code)s has already been used.',
        packError:
          'Discounts cannot be combined with other promotions. Please remove the packs from your cart in order to use a discount code.',
      },
      placeholder: 'Discount code',
      submit: 'Apply',
      remove: 'Remove',
    },
    empty: {
      headline: 'There are currently no images in your cart.',
      text: "Let's change that. %(searchLink)s or %(collectionsLink)s",
      searchLink: 'Start searching now',
      collectionsLink: 'browse our collections',
    },
    steps: {
      eventPosition: 'checkout',
      edit: 'Edit',
      billing: {
        title: 'Billing Information',
        submit: 'Continue',
        eventName: 'checkout_edit_billing',
      },
      payment: {
        title: ' Payment Method',
        eventName: 'checkout_edit_payment',
      },
      confirmation: {
        title: ' Confirm Order',
        tax: 'Tax',
        subtotal: 'Subtotal',
        total: 'Total',
        creditCardSubmit: 'Buy now',
        paypalSubmit: 'Buy using PayPal',
        agreement:
          "By placing this order you accept EyeEm's %(tos)s, %(license)s and %(marketprivacy)s",
      },
    },
    download: {
      headline: 'Thank you',
      headline2: 'Your images are being prepared.',
      signup: {
        headline: 'Sign up to EyeEm',
        subtext: 'Keep access to your images and checkout faster',
      },
      signupButton: 'Sign up',
      preparing: 'Preparing Images',
      text: "Images may take a minute to download. Don’t want to wait? We'll email you a download link shortly. Invoices can be found in your %(purchaseHistoryLink)s.",
      textv2:
        'You will receive a confirmation email shortly from market@eyeem.com',
      orderNumber: 'Order Number: %(paymentId)s',
      link: 'Download History',
      button: 'Images',
      buttonInvoice: 'Invoice',
      w8: 'If required, you can download %(w8Link)s.',
      w8Link: 'the W-8BEN form here',
      details: {
        one: '1 Item',
        other: '%(count)s Items',
      },
      error:
        "Either this transaction doesn't exist or you don't have the necessary permissions to see it.",
    },
    paymentError: {
      default:
        'Sorry. Something went wrong. Please check your credit card information and try again',
      headline:
        'An unforeseen error has occurred while processing your transaction. Please try again.',
      7001: 'There was a problem with your credit card data. Please fix it and try again.',
      7002: "There was a problem processing your payment. Your card hasn't been charged. We recommend that you contact your card issuer for more information.",
      CLIENT_GATEWAY_NETWORK:
        "There was a problem reaching our servers. Your card hasn't been charged. Please try again.",
      CLIENT_REQUEST_TIMEOUT:
        "There was a problem reaching our servers. Your card hasn't been charged. Please try again.",
      CLIENT_REQUEST_ERROR:
        'There was a problem with your credit card data. Please fix it and try again.',
      CLIENT_RATE_LIMITED:
        "There was a problem processing your payment. Your card hasn't been charged. Please try again in a few minutes.",
      PAYPAL_POPUP_CLOSED:
        'You may have closed the Paypal window too early. Try again!',
      PAYPAL_POPUP_OPEN_FAILED:
        '👋 Please allow pop-ups to appear from our domain. PayPal depends on it! Check your browser address bar for a prompt, or adjust your browser security settings.',
      THREEDS_HTTPS_REQUIRED: '',
    },
    photoBy: 'By %(fullname)s',
    imageSize: 'Size: %(width)s x %(height)spx',
    photoId: 'ID: #%(photoId)s',
    modals: {
      findOutMore: 'Find out more about licensing agreements',
      vat: {
        title: 'VAT',
        body: 'Value-added tax will be calculated at checkout based on your country and account type.',
      },
      accountType: {
        title: 'Account Selection',
        body: 'This setting indicates whether your account is for business or personal use. You only have to select this once. If you would like to modify your account type, please contact market@eyeem.com.',
        confirm: 'Tell me more',
        cancel: 'Got it!',
      },
      countryRestriction: {
        title: "Why can't I change my country?",
        body: 'We assume that you are located in this country. If that’s not correct, please get in touch with us at market@eyeem.com.',
      },
      upgradeDifference:
        'For this upgrade, you will only be charged the price difference between the original license and the new one.',
    },
    progress: {
      stage1: 'Summary',
      stage2: 'Checkout',
      stage3: 'Download',
    },
    auth: {
      title: 'Choose how you would like to check out',
      login: {
        title: 'Returning Customer',
        subtitle: 'Keep access to your images and checkout faster.',
        submit: 'Continue to checkout',
      },
    },
    sidebar: {
      checkoutBlocked:
        "It seems that you're currently located outside one of our supported countries. Keep an eye on our blog to find out when we launch in your country, or get in touch at market@eyeem.com.",
      error: {
        editorialUseOnlyAll:
          'Note: All images in your cart are for editorial use only.',
        editorialUseOnlyFraction:
          'Note: %(editorialCount)s of %(count)s images in your cart are for editorial use only.',
      },
      images: {
        zero: '%(count)s images',
        one: '%(count)s image',
        other: '%(count)s images',
      },
      summary: {
        title: 'Summary',
        checkoutTitle: 'In your cart',
        items: {
          zero: 'No items',
          one: '1 item',
          other: '%(count)s items',
          coveredByPack:
            '%(count)s cart items will be deducted from your remaining pack downloads.',
        },
      },
      tax: 'Tax',
      subtotal: 'Subtotal',
      discountedSubtotal: 'Discounted',
      orderTotal: 'Total',
      readAboutLicenseDetailsLink: 'Review license agreements',
      readAboutLicenseDetails: '%(link)s',
      buttonCheckoutNow: 'Check out now',
      licenseAlreadySelected:
        'Go to the cart to change or remove your license type',
      buttonBuyNow: 'Buy Now',
      accept: 'We accept',
      buttonBuyNowBlocked: 'Checkout not possible',
      buttonBuyNowPaypal: 'Proceed with PayPal',
      buttonProcessingPayment: 'Processing Payment',
      benefits: {
        benefit1: 'Royalty-free',
        benefit2: 'Worldwide usage',
        benefit3: 'No expiration',
      },
      checkboxes: {
        labelTerms:
          'I have read and understood the EyeEm %(tos)s, %(license)s and %(marketprivacy)s',
        tos: 'Terms of Service',
        license: 'License Agreement',
        marketPrivacyPolicy: 'Market Privacy Policy',
        required:
          'Please accept the following conditions to proceed with your purchase.',
      },
      removeModal: {
        reallyRemove: 'Do you really want to remove this photo?',
        approveButton: 'Remove Photo',
        cancelButton: 'Cancel',
      },
      contact: {
        eventName: 'cta_contact_modal',
        title: 'Pay by invoice or in a different currency',
        link: 'contact our sales team',
        paragraph:
          'Do you want to pay by invoice or in a different currency? Please %(salesTeam)s.',
      },
      vat: 'VAT %(rate)s%',
      vatNoteMaybe:
        'Value-added tax will be calculated at checkout based on your country and account type.',
      vatNote:
        'Value-added tax is calculated based on your country and account type.',
    },
    cartItem: {
      photo: {
        eventPosition: 'checkout',
        details: {
          eventName: 'checkout_edit_cart',
          size: 'Size',
          image: 'Image',
          edit: 'Edit',
          remove: 'Remove',
        },
        licenseChooser: {
          updateButton: 'Update license type',
        },
      },
      deal: {
        details: {
          downloadAmount: 'Images available: %(amount)s',
          expiry: 'Expiry date: 1 year',
        },
      },
      any: {
        addToCart: 'Add',
        saveForLater: 'Save',
      },
    },
    addToCart: {
      quickAdd: 'Download',
      goToCart: 'Download now',
      flyout: {
        by: 'by %(user)s',
        checkout: {
          go: 'Check out now',
          eventName: 'ecom_checkout',
          eventPosition: 'navbar_cartFlyout',
        },
        cart: {
          go: 'View cart',
          eventName: 'access_cart',
          eventPosition: 'navbar_cartFlyout',
        },
      },
    },
    payment: {
      title: 'Select Payment',
      cardName: 'Name on card',
      cardNumber: 'Card Number',
      expiration: {
        month: 'MM',
        year: 'YY',
        ccv: 'CVV',
        monthyear: 'MM / YY',
      },
      method: {
        title: 'Payment Method',
        creditCard: 'Debit/Credit Card',
        paypal: 'PayPal',
        required: 'Please select a payment method.',
      },
      redirection: {
        paypal: 'You’ll be redirected to PayPal’s website.',
      },
      ssl: {
        title: 'SSL - ',
        description: 'Secure connection',
      },
    },
    account: {
      malformed: {
        email: 'This is not a valid email format. Please try again.',
        password:
          'Please use a more secure password with at least 5 characters.',
        taxId: 'Please check that you entered a valid VAT number.',
      },
      passwordRequired: 'Password Required',
      wrongCredentials:
        'The password you entered is incorrect. Please try again.',
      login: {
        title: 'Login to EyeEm',
        errorUnknown:
          "We couldn't log you in. Please try again or contact support@eyeem.com",
      },
      signup: {
        title: 'Create an account',
        errorUnknown:
          "We couldn't sign you up. Please try again or contact support@eyeem.com",
      },
      createAnAccount: 'Create a new EyeEm Account',
      signInToYourAccount: 'Log in to your EyeEm Account',
      accountInformation: 'Account Information',
      loggedInAs: 'Signed in as %(mail)s',
      loggedIn: 'Logged in as',
      haveAccount: 'Have an account already? %(logIn)s',
      logIn: 'Log In',
      dontHaveAccount: "Don't have an account? ",
      dontHaveAccountPlain: "Don't have an account? ",
      createNewOne: 'Create one',
      forgetPassword: 'Forgot Password?',
      email: 'Email',
      passwordSignup: 'Choose a password',
      passwordLogin: 'Password',
      type: {
        1: 'Personal account',
        2: 'Business account',
        required: 'Please select your account type',
      },
      eventName: {
        valid: 'buyerLogin_complete_valid',
        invalid: 'buyerLogin_complete_invalid',
      },
      eventPosition: 'checkout_signup_form',
    },
  },
  transactions: {
    headline: 'Order History',
    download: 'Download',
    downloadImages: {
      one: '%(download)s Image',
      other: '%(download)s Images',
    },
    downloadInvoice: '%(download)s Invoice',
    photoLicenses: {
      one: '%(count)s photo license',
      other: '%(count)s photo licenses',
    },
    empty: {
      text: "You haven’t made any purchases yet. As soon as you do, they'll show up here.",
      searchLink: 'Start searching',
    },
    side: {
      headline: 'Questions?',
      text: 'We’re happy to help! Visit %(marketSupportLink)s for more information on pricing, taxes and licenses.',
      marketSupportLink: {
        label: 'Market Support',
        href: 'https://eyeem.zendesk.com/hc/en-us/',
      },
    },
  },
  widget: {
    expandable: {
      expand: 'Expand',
      collapse: 'Collapse',
    },
    embeddableSearch: {
      placeholder: 'e.g. girl camping in United States',
    },
  },
  photoCredit: {
    photoBy: 'By',
  },
  settings: {
    title: 'Settings',
    profile: {
      title: 'Profile',
      description: 'Change the details of your public profile.',
      profileImage: 'Profile Photo',
    },
    coverPhoto: {
      change: 'Change',
      label: 'Cover Photo',
      minSize: 'Min. resolution: 1200x600px',
      modal: {
        title: 'Select your Cover Photo',
        cancel: 'Cancel',
        confirm: 'Confirm',
        lowResolutionHint:
          'The resolution is too low to be used as a cover photo.',
      },
    },
    account: {
      fullname: 'Name',
      nickname: {
        label: 'Username',
        validation: {
          invalid: 'This is not a valid username',
          unavailable: 'This username is not available',
        },
      },
      description: 'Profile text',
      homepage: 'Homepage',
      homepageUnverified:
        'Homepage - You will need to verify your Email first.',
      email: 'Email',
      password: 'Password',
      confirmPassword: 'Confirm Password',
      save: 'Save changes',
      delete: {
        label: 'Delete account',
        eventName: 'access_modal_deleteUser',
      },
      eventPosition: 'settings_account',
    },
    emailPassword: {
      title: 'Email and Password',
      description: "Change your account's email and password.",
    },
    deleteAccount: {
      title: 'Delete account',
      description:
        'Once you delete your account, there is no way to undo it. Please be certain.',
    },
    notifications: {
      title: 'Email Notifications',
      description:
        'Update the type of email notifications that you want to receive.',
      email_photo_like: 'Photo likes',
      email_photo_comment: 'Photo comments',
      email_user_follower: 'New follower',
      email_user_joined: 'A friend joins EyeEm',
      email_photo_comment_mention: '@ mentions',
      email_community_updates: {
        creator: 'Newsletter',
        buyer: 'Visual insights and updates from EyeEm',
      },
      email_market_model_release_completed: 'Market: Model release completed',
      email_market_property_release_completed:
        'Market: Property release completed',
    },
    socialServices: {
      title: 'Integrations',
      connected: 'Connected',
      disconnect: 'Disconnect',
      connect: 'Connect',
      serviceNames: {
        twitter: '%(icon)sTwitter',
        facebook: '%(icon)sFacebook',
      },
      eventName: {
        connect: 'socialService_connect',
        disconnect: 'socialService_disconnect',
      },
      eventPosition: 'settings_socialServices',
    },
    linkedApps: {
      title: 'Authorized Apps',
      disconnect: 'Remove App',
    },
    integrations: {
      title: 'Integrations',
      connected: 'Connected',
      disconnect: 'Disconnect',
      connect: 'Connect',
      serviceNames: {
        twitter: '%(icon)sTwitter',
        facebook: '%(icon)sFacebook',
      },
    },
    team: {
      headline: 'My Team',
      subline: {
        zero: '%(count)s Users',
        one: '%(count)s User',
        other: '%(count)s Users',
      },
      status: {
        INVITED: 'Invited',
        INACTIVE: 'Deactivated',
        ACTIVE: 'Active',
      },
      role: {
        customer: {
          admin: {
            label: 'Admin',
            subtext: 'Manages team budget, members and permissions.',
          },
          buyer: {
            label: 'Buyer',
            subtext:
              'Can purchase new photos using the team budget and invite new buyers and viewers to the team.',
          },
          member: {
            label: 'Viewer',
            subtext:
              'Cannot purchase new photos, but can view and download previously bought images again. Can invite new viewers to the team.',
          },
        },
        wasChanged: '%(user)s was successfully changed to %(role)s.',
        modal: {
          title: 'Confirm change',
          roleChange: 'Role change',
          downgradeWarning:
            'Your access to managing roles and status of users will be revoked on confirmation',
          cancel: 'Cancel',
          confirm: 'Confirm',
        },
      },
      invite: {
        trigger: 'Invite Users',
        modal: {
          title: 'Invite Users',
          label: 'Emails',
          placeholder: 'anna@company.com, joe@company.com',
          invalidEmail: {
            one: '"%(emails)s" isn\'t a valid email address',
            other: '"%(emails)s" aren\'t valid email addresses',
          },
          tooManyEmails: 'Please add no more than 50 email addresses',
          cancel: 'Cancel',
          confirm: 'Invite',
        },
        notification: {
          success: 'Users were successfully invited',
          error:
            "The following emails couldn't be added: %(emails)s. Please remove them and try again.",
        },
      },
      actions: {
        reinvite: 'Reinvite',
        wasReinvited: 'We’ve sent a new invite to %(email)s.',
        wasDeactivated: '%(user)s is now deactivated.',
        deactivate: 'Deactivate',
        modal: {
          title: 'Deactivate User',
          confirm: 'Deactivate User',
          cancel: 'Cancel',
        },
      },
    },
  },
  staticPages: {
    subnavigation: {
      info: {
        press: 'Press',
        missions: 'Missions',
        blog: 'Blog',
      },
      company: {
        jobs: 'Jobs',
        contact: 'Contact',
      },
      settings: {
        account: 'Edit Account',
        notifications: 'Email Notifications',
        services: 'Social Sharing',
        integrations: 'Integrations',
        apps: 'Authorized Apps',
      },
    },
    legal: {
      oldTos: {
        headline: 'Previous Terms of Service',
        subline:
          'You can access our previous Community Terms of Service %(community)s, previous Buyer Terms of Service %(buyers)s and previous Photographer Terms of Service %(photographers)s.',
        here: 'here',
      },
      nonLawyers: 'For Non-Lawyers',
      sidebar: {
        tos: 'Terms of Service',
        privacy: 'Privacy Policy',
        imprint: 'Imprint',
        webTos: 'Website Terms',
      },
    },

    contact: {
      title: 'We want to hear from you.',
      text: 'Have questions? We have answers!%(br)sFirst, let’s narrow it down...',
      support: {
        title: 'Support',
        subline: 'FAQs and technical support',
        eventName: 'access_help',
        href: 'https://eyeem.zendesk.com/hc/en-us/requests/new',
      },
      press: {
        title: 'Press',
        subline: 'Media enquiries and press kit',
        eventName: 'access_press',
      },
      partnerships: {
        title: 'Partnerships',
        subline: 'Business and commercial enquiries',
        eventName: 'cta_contact_modal',
      },
      socialLinks: 'Join us on',
      eventPosition: 'contactPage',
    },
    tos: {
      en: {
        tos: {
          headline: 'Terms of Service',
          download: 'Download Terms of Service PDF',
          pdfLink:
            'https://dtpuu5koy2g08.cloudfront.net/market/TOS_EN_apr16.pdf',
        },
        privacy: {
          headline: 'Privacy Policy',
          download: 'Download Privacy Policy PDF',
          pdfLink:
            'https://dtpuu5koy2g08.cloudfront.net/market/PP_EN_may18.pdf',
        },
        webTos: {
          headline: 'Website Terms of Service',
          download: 'Download Website Terms of Service PDF',
          pdfLink:
            'https://dtpuu5koy2g08.cloudfront.net/market/WEBTOS_EN_oct17.pdf',
        },
      },
      de: {
        tos: {
          headline: 'Nutzungsbedingungen',
          download: 'Nutzungsbedingungen als PDF herunterladen',
          pdfLink:
            'https://dtpuu5koy2g08.cloudfront.net/market/TOS_DE_apr16.pdf',
        },
        privacy: {
          headline: 'Datenschutzrichtlinien',
          download: 'Datenschutzrichtlinien als PDF herunterladen',
          pdfLink:
            'https://dtpuu5koy2g08.cloudfront.net/market/PP_DE_may18.pdf',
        },
        webTos: {
          headline: 'Website-Nutzungsbedingungen',
          download: 'Website-Nutzungsbedingungen als PDF herunterladen',
          pdfLink:
            'https://dtpuu5koy2g08.cloudfront.net/market/WEBTOS_DE_oct17.pdf',
        },
      },
    },
  },
  dropDownTracking: {
    open: '_open',
    close: '_close',
  },
  landing: {
    market: {
      quote:
        'EyeEm is a creative%(br)scommunity & marketplace%(br2)sfor real photography.',
      button: 'Learn more',
    },
    sellerInfo: {
      sellingPoints: {
        revenue: {
          title: 'Make money every time you sell',
          text: 'A photo can sell again and again to multiple clients - and you’ll earn money each time.',
        },
        copyright: {
          title: 'Always keep your copyright',
          text: 'You choose which photos you want to sell and always remain the owner of your shots.',
        },
        distribution: {
          title: 'Sell with Getty and more',
          text: 'We partner up with the world’s leading photo agencies to get your work seen by even more buyers.',
        },
      },
    },
    sellerSignup: {
      header: {
        title: 'Enter your contributor details',
        text: 'This is needed to pay you when your work sells. This information will be kept safe and never passed on.',
      },
      redirectBanner: {
        illustrations:
          'You need to complete your signup as a contributor before you can upload your illustrations.',
      },
      form: {
        accountType: "I'm selling via a company",
        birthday: "I'm 18 years of age or older",
        button: 'Sign Up',
        success: {
          headline: 'Great job, %(user)s!',
          subline:
            'You are now registered as an EyeEm Market contributor. Simply upload your work to get started!',
          dashboard: 'Start uploading',
        },
        failure: {
          headline: 'Something went wrong...',
        },
        quit: {
          headline: 'Are you sure?',
          subline:
            "If you don't register for Market, you won't be able to sell your work.",
          continue: 'Continue Registration',
          quit: 'Quit',
        },
      },
      confirmNavigationNative: 'You have unsaved changes.',
      successModal: {
        headline: "Nice work %(firstName)s, you're ready to sell!",
        subline:
          'Upload all your best commercial work to increase your chances of selling.',
        cta: 'Start Uploading',
      },
    },
    missions: {
      eventPosition: 'missions',
      topSlide: {
        headline: 'Discover EyeEm Missions',
        subline:
          'Where the creative community meets global brands and publications.',
        button: 'Let’s talk!',
      },
      latestSlide: {
        headline: 'Latest Missions',
        more: 'Read More',
      },
      brandsIntro: {
        headline: 'EyeEm Missions for Brands',
        subline:
          'Tap into our global community of creatives to tell your brand’s story.',
        button: 'Let’s talk',
      },
      partnerSlider: {
        engagements: 'Engagements with Mission',
        engagingMoments: 'Engaging Moments',
        engagedPhotographers: 'Engaged Photographers',
        partners: {
          motorola: {
            quote:
              '"We were blown away with both the number of submissions and the range of creative imagery from the EyeEm community."',
            citation: 'Christopher Clare, Creative Director, Motorola',
            bottomLine: '“Choose Color!” by Motorola',
          },
          bcg: {
            quote:
              '"Through partnering with EyeEm, we were able to source original and authentic images that give a unique look to our website."',
            citation: 'Massimo Portincaso, Global Marketing Director, BCG',
            bottomLine: '“Visualize The Future” by BCG',
          },
          applemusic: {
            engagements: '420,000+',
            engagingMoments: '5,700+',
            engagedPhotographers: '3,100+',
            bottomLine: '“For the love of music” by Apple Music Festival',
          },
          gettyimages: {
            engagements: '860,000+',
            engagingMoments: '15,500+',
            engagedPhotographers: '6,500+',
            bottomLine: '“Everyday Joy” by Getty Images',
          },
          huffingtonpost: {
            engagements: '720,000+',
            engagingMoments: '13,300+',
            engagedPhotographers: '7,700+',
            bottomLine:
              '“What does Peace look like to you?” by Huffington Post',
          },
          mercedesbenz: {
            engagements: '870,000+',
            engagingMoments: '8,100+',
            engagedPhotographers: '4,100+',
            bottomLine: '“Elegance Everywhere” by Mercedes-Benz',
          },
        },
      },
      features: {
        licensing: {
          headline: 'Straightforward licensing',
          subline:
            'Accommodating, pragmatic licensing options, from royalty-free to exclusive',
        },
        speed: {
          headline: 'Quick turnaround',
          subline:
            'We work fast, getting you from concept to launch in just 24 hours',
        },
        pricing: {
          headline: 'Transparent pricing model',
          subline:
            'Solutions tailored to your brand’s individual budget and requirements',
        },
        engagement: {
          headline: 'Engaging brand placement',
          subline:
            'Be at the center of the conversation and directly engage with your intended audience',
        },
      },
      contact: {
        headline: 'Let’s talk!',
        subline:
          'Enter your details to get in touch. You’ll hear back from us within 24 hours.',
        namePlaceholder: 'Your name',
        placeholderEmail: 'Email',
        nameError: 'Please enter a name',
        emailError: 'Please enter a valid email address',
        button: {
          label: 'Find out more',
          eventName: 'submit_mission_contact',
        },
      },
    },
  },
  profile: {
    releasesHeader: {
      headline: 'Ask For Model Releases',
      subline:
        'These photos contain recognisable people and require signed model releases to be sold with a commercial license on EyeEm Market and our distribution partner platforms. Please get permission from each identifiable person by asking them to sign a model release, or simply remove the photos where you can’t get a release.',
      photoCount: {
        zero: 'No photos need Model Releases',
        one: '%(number)s photo needs Model Releases',
        other: '%(number)s photos need Model Releases',
      },
      modalTrigger: {
        label: 'Release For Multiple Photos',
        eventName: 'access_multiReleases',
      },
      eventPosition: 'user_releases',
      tooltip: {
        body: 'If the same model appears in several photos, you can group them and create one multi release for all of them',
        eventName: 'user_releases_tooltip',
      },
    },
  },
  multipleReleases: {
    header: {
      eventPosition: 'multiRelease_photos',
      buttons: {
        next: {
          label: 'Next',
          eventName: 'multiReleases_photos_next',
        },
        cancel: {
          label: 'Cancel',
          eventName: 'multiReleases_photos_close',
        },
        done: {
          label: 'Done',
          eventName: 'multiReleases_photos_done',
        },
      },
    },
    selectModal: {
      headline: 'Does the same person appear in several photos?',
      subline:
        'Select all photos containing the same person. In the following steps, you’ll be able to create a single release request link.',
      deselectAll: 'Deselect all',
      photoCount: {
        zero: '%(number)s photos selected',
        one: '%(number)s photo selected',
        other: '%(number)s photos selected',
      },
      eventPosition: 'multiRelease_photos',
    },
    selectionStrip: {
      headline: {
        zero: 'Model appears in %(boldCount)s photos',
        one: 'Model appears in %(boldCount)s photo',
        other: 'Model appears in %(boldCount)s photos',
      },
      headlineWithName: {
        zero: '%(firstName)s %(lastName)s appears in %(boldCount)s photos',
        one: '%(firstName)s %(lastName)s appears in %(boldCount)s photo',
        other: '%(firstName)s %(lastName)s appears in %(boldCount)s photos',
      },
      eventPosition: 'multiRelease_selectionStrip',
      edit: {
        label: 'Edit',
        eventName: 'multiReleases_request_edit',
      },
      view: {
        label: 'View',
        eventName: 'multiReleases_request_view',
      },
    },
    releaseInfo: {
      eventPosition: 'multiRelease_request',
      next: {
        label: 'Next',
        eventName: 'multiReleases_request_next',
      },
      back: {
        label: 'Back',
        eventName: 'multiReleases_request_back',
      },
      form: {
        headline: 'Enter model name',
        labels: {
          firstName: "Model's first name",
          lastName: "Model's last name",
        },
      },
    },
    confirm: {
      eventPosition: 'multiRelease_requestConfirm',
      infoBox: {
        title: 'Are all details correct?',
        text: 'After this step, you’ll no longer be able to modify the model’s name or add/remove photos in your selection.',
      },
      next: {
        label: 'Next',
        eventName: 'multiRelease_requestConfirm_next',
      },
      edit: {
        label: 'Edit',
        eventName: 'multiRelease_requestConfirm_edit',
      },
    },
    askModel: {
      eventPosition: 'multiRelease_request',
      needed: 'Ask',
      pending: 'Remind',
      subline:
        'Get permission to sell these photos by copying the release form link below and sending it to %(firstName)s %(lastName)s',
      button: {
        copy: 'Copy',
        copied: 'Copied!',
        eventName: 'multiReleases_request_copy',
      },
    },
    view: {
      headline: {
        zero: '%(modelName)s appears in %(boldCount)s photos',
        one: '%(modelName)s appears in %(boldCount)s photo',
        other: '%(modelName)s appears in %(boldCount)s photos',
      },
    },
    removeReleaseWarning: {
      headline: 'Removing from one photo',
      subline:
        "The photo you're removing the release requirement from is part of a multiple release. The release requirement will be removed from the current photo and the photo will be removed from the multiple release.",
      button: {
        cancel: 'Cancel',
        remove: 'Remove',
      },
    },
  },
  upload: {
    noAccess: {
      unsupportedDevice: {
        headline: 'Device not supported',
        subline:
          'To keyword your photos, download the EyeEm app for iOS and Android devices.',
        button: 'Get the app',
      },
      regular: {
        headline: 'Love photography? Come on in.',
        subline:
          'EyeEm is a photo community where you can learn, get discovered and make money.%(br)sUpload photos directly or import them from Flickr and more. Then let our technology tag them for you!',
        button: 'Let‘s get started',
      },
      phone: {
        headline: 'One more step to upload',
        subline:
          'Download EyeEm to upload images straight from your phone. Or return to this page from your desktop to use web upload.',
        button: 'Get the app',
      },
    },
    confirmNavigationNative: 'There are unposted photos.',
    disabledMessage: 'Select one or more photos to edit this information',
    addToMarketTooltip: {
      title: 'Sell your photo',
      body: 'Check this box to submit your photos to be reviewed for sale on EyeEm Market as well as Getty Images and other partners via the Partner Collection. Learn more about how much you can earn in the %(bodyLink)s.',
      bodyLink: {
        label: 'EyeEm Market FAQs',
        href: 'https://eyeem.zendesk.com/hc/en-us/',
      },
      extraTitle: 'Which photos should I add to Market?',
      extraBody:
        'Only upload photos you have taken yourself. To find out what buyers are looking for, check out our %(bodyLink)s.',
      extraBodyLink: {
        label: 'blog',
        href: 'https://www.eyeem.com/magazine',
      },
    },
    landing: {
      headline: 'Upload your photos',
      button: 'Select from computer',
      buttonIos: 'Select from camera roll',
      buttonAndroid: 'Select from gallery',
      subline:
        'You can upload up to %(maxFiles)s photos per batch. Please upload photos without watermarks.',
      drophint: 'You can also drag and drop them here',
      coverLabel: 'Drop photos here',
    },
    selectAll: 'Select all',
    multipleSelection: {
      hint: 'Use %(button)s to select multiple photos',
      button: {
        mac: 'CMD',
        other: 'CTRL',
      },
    },
    postPhotos: {
      one: 'Post photo',
      other: 'Post all photos',
    },
    removePhoto: {
      eventName: 'upload_edit_deletePhoto',
    },
    uploadingPhotos: {
      one: 'Uploading %(count)s photo...',
      other: 'Uploading %(count)s photos...',
    },
    postingPhotos: 'Posting photos',
    addMorePhotos: 'Add more photos',
    lowResolutionHint:
      'The resolution is too low to be accepted on EyeEm Market. Please make sure you upload photos of at least 4MP in size.',
    sidebar: {
      headline: {
        zero: 'Select photos to edit',
        one: 'Editing one photo',
        other: 'Editing %(count)s photos',
      },
      discoverBar: {
        headline: 'Discoverability',
        level: {
          0: 'poor',
          1: 'moderate',
          2: 'moderate',
          3: 'really good',
        },
        subline:
          'Fill out market details to make your photo findable and increase its chances of selling.',
      },
      addToMarket: {
        label: 'Add to Market',
        subline:
          'Earn money when your photo is sold on EyeEm Market or partner sites.',
        marketMission:
          'The mission you want to submit your photo to is a market mission. Your submission has to be added to market in order to submit it to the mission.',
      },
      descriptionHeader: 'Add Caption',
      descriptionPlaceholder: {
        regular: 'Add a descriptive caption...',
        mixed: 'Replace all captions...',
      },
      albumsHeader: 'Add Tags',
      albumsPlaceholder: {
        regular: 'Add tags to your photo...',
        mixed: 'Replace all tags...',
        more: 'Add more tags...',
      },
      albumsInput: {
        limitReached: 'Limit reached. Remove tags to add more...',
        popularTags: 'Popular Tags',
        createTag: 'Create tag "%(itemName)s"',
        photos: {
          one: '1 photo',
          other: '%(countAbbr)s photos',
        },
        loading: 'Loading...',
        noAlbum: 'No tags found',
      },
      locationHeader: 'Add Location',
      locationPlaceholder: {
        regular: 'Add a location to your photo...',
        mixed: 'Replace all locations...',
      },
      locationInput: {
        popularLocations: 'Popular Locations',
        loading: 'Loading...',
        noLocation: 'No location found',
      },
      keywordsInput: {
        headline: 'Tag suggestions',
        selectedTags: 'Selected tags',
        loading: 'Loading tag suggestions...',
        noIntersection:
          'The selected photos have no tag suggestions in common.',
        allAdded: 'All suggested tags were added!',
        addAll: 'Add all %(count)s',
      },
    },
  },
  appBanner: {
    headline: 'EyeEm',
    subline: {
      Android: 'Free on Google Play',
      iOS: 'Free on the App Store',
    },
    button: 'Open',
  },
  grid: {
    showMore: 'Show more',
    noResults: 'No results',
    pagination: {
      eventPosition: 'paginationControls',
      nextPage: {
        label: 'Next',
        eventName: 'access_grid_next',
      },
      prevPage: {
        label: 'Prev',
        eventName: 'access_grid_previous',
      },
      dots: '...',
      page: 'page',
    },
    photoCard: {
      eventName: 'access_asset',
      eventLabel: '',
      comment: {
        eventLabel: 'comment',
      },
      feedback: {
        label: '...',
        eventLabel: 'Feedback',
        eventName: 'access_search_feedback',
      },
      similarPhotos: {
        label: 'Not Similar',
        eventName: 'access_asset',
        eventPosition: 'similar_photos',
      },
      releasesNeeded: {
        zero: 'Model Releases needed',
        one: 'Model Release needed',
        other: 'Model Releases needed',
      },
      cantGetReleases: {
        eventName: 'releases_cantClear',
        label: {
          zero: "I can't get releases",
          one: "I can't get the release",
          other: "I can't get releases",
        },
      },
      manageReleases: {
        label: 'Manage Releases',
        eventName: 'releases_manage',
      },
      aestheticsBar: {
        label: 'Engagement score',
        value: '%(percent)s%',
      },
      overlay: {
        lightbox: 'Saved',
        inCart: 'Added to cart',
        purchased: 'Licensed',
        hiRes: 'Hi-Res Preview',
      },
    },
  },
  forms: {
    label: {
      generic: 'Element',
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      birthday: 'Date of Birth',
      company: 'Company Name',
      taxId: 'VAT Number',
      city: 'City',
      address: 'Street',
      zip: 'Zip Code',
      state: 'State (optional)',
      gender: {
        male: 'Male',
        female: 'Female',
        other: 'Other',
      },
      password: {
        VALIDATOR_MIN_LENGTH: '8 characters minimum',
        VALIDATOR_PASSWORD_LOWER: 'One lowercase character',
        VALIDATOR_PASSWORD_UPPER: 'One uppercase character',
        VALIDATOR_PASSWORD_NUMBER: 'One number',
        VALIDATOR_PASSWORD_SPECIAL_CHAR: 'One symbol: !@#$ etc',
      },
    },
    placeholder: {
      firstName: 'First Name',
      lastName: 'Last Name',
      name: 'Name',
      email: 'name@example.com',
      date: {
        day: 'DD',
        month: 'MM',
        year: 'YYYY',
      },
      country: 'Country',
      company: 'Company Name',
      companyOptional: 'Company Name (optional)',
      taxId: {
        required: 'VAT Number',
        optional: 'VAT Number (optional)',
      },
      address: 'Street',
      city: 'City',
      zip: 'Zip',
      state: 'State (optional)',
      modelRelease: {
        firstName: "Model's first name",
        lastName: "Model's last name",
        parentFirstName: "Parent's first name",
        parentLastName: "Parent's last name",
        modelEmail: "Model's email address",
        parentEmail: "Parent's email address",
      },
      propertyRelease: {
        propertyAddress: 'Address of Property',
        ownerAddress: 'Address of Owner',
        ownerFirstName: "Owner's First Name",
        ownerLastName: "Owner's Last Name",
        selectPropertyCategory: '- Select Property Category -',
      },
      accountSettings: {
        fullname: 'Name',
        nickname: 'Username',
        description: 'Write something about yourself...',
        homepage: 'Your personal homepage',
        email: 'Email',
        password: 'Password',
        newPassword: 'New Password',
        confirmPassword: 'Confirm Password',
      },
    },
    fileInput: {
      upload: 'Upload',
      change: 'Change',
      remove: 'Remove',
      formats: 'Supported formats: %(formats)s',
      resolution: 'Min. resolution: %(resolution)s',
      pngOrJpeg: 'JPEG or PNG',
    },
    show: 'show',
    hide: 'hide',
    button: {
      next: 'Next',
      back: 'Back',
      done: 'Done',
      showCollapsibleFields: 'Company Name & Tax Number',
    },
    error: {
      generic: '%(elementName)s is invalid.',
      mandatory: '%(elementName)s is mandatory.',
      checked:
        "Please confirm that you are over 18 years old. If you're under 18, you cannot register to sell on EyeEm Market.",
      selected: 'Please select one of the provided values.',
      adult: 'To register, you must be 18 or older.',
      fullname: 'Your name can be between 2 and 128 characters',
      countryPaypal:
        "Unfortunately PayPal doesn't support receiving payments in this country. If you have an address in another country, please try entering it instead.",
      typeOfPropertySelected: 'Please select the type of property.',
      genderSelected: 'Please select the gender of the model.',
      password: 'Your password must contain at least 8 characters.',
      description: 'Your bio can be up to 130 characters.',
      emailUnavailable: 'This email address is already in use.',
      ccNumber: 'Your credit card number should be 13, 16 or 19 digits long.',
      VALIDATOR_REQUIRED: 'This field is mandatory',
      VALIDATOR_ZIP: "Zip code isn't valid",
      VALIDATOR_ZIP_MAX_LENGTH: 'Zip code too long (max 10 digits)',
      VALIDATOR_UNICODE_STRING: 'This is not a valid value',
      VALIDATOR_INT: 'This is not a valid number',
      VALIDATOR_STRING: 'This is not a valid string',
      VALIDATOR_EMAIL: 'This is not a valid email address',
      VALIDATOR_CHARGECODE: 'This is not a valid charge code',
      VALIDATOR_CHARGECODE_ERROR:
        'Charge code validation failed, please contact your supervisor',
      VALIDATOR_PASSWORD: 'Your password must contain at least 8 characters.',
      VALIDATOR_ENTERPRISE_PASSWORD:
        'Your password must contain at least 8 characters.',
      VALIDATOR_PASSWORD_OR_EMPTY:
        'Your password must contain at least 8 characters.',
      VALIDATOR_ENTERPRISE_PASSWORD_OR_EMPTY:
        'Your password must contain at least 8 characters.',
      PASSWORDS_MUST_MATCH: 'Passwords must match',
      VALIDATOR_MIN_LENGTH: '8 characters minimum',
      VALIDATOR_PASSWORD_LOWER: 'One lowercase character',
      VALIDATOR_PASSWORD_UPPER: 'One uppercase character',
      VALIDATOR_PASSWORD_NUMBER: 'One number',
      VALIDATOR_PASSWORD_SPECIAL_CHAR: 'One symbol: !@#$ etc',
      VALIDATOR_ADULT: 'To register, you must be 18 or older.',
      VALIDATOR_DATE: 'Please provide a full date',
      VALIDATOR_TAX_ID: "Tax ID isn't valid",
      VALIDATOR_PAYPAL_COUNTRY:
        "Unfortunately PayPal doesn't support receiving payments in this country. If you have an address in another country, please try entering it instead.",
      VALIDATOR_BCG_EMAIL:
        'Please log in through %(link)s using your Okta credentials.',
      startDateBeforeEndDate:
        'Please provide a start date that is before the end date.',
      endDateBeforeStartDate:
        'Please provide an end date that is after the start date.',
      LINK_MUST_BE_VALID: `Must start with either 'http://' or 'https://'`,
      VALIDATOR_CAPTION_TOO_SHORT: 'Caption should be at least 10 characters',
      VALIDATOR_CAPTION_TOO_LONG: 'Caption should be less than 100 characters',
      VALIDATOR_CAPTION_CHARS: 'The caption contains unsupported characters',
      VALIDATOR_NOT_ENOUGH_KEYWORDS: 'Add at least 5 keywords',
      VALIDATOR_TOO_MANY_KEYWORDS_50: 'Too many keywords selected',
      VALIDATOR_TOO_MANY_KEYWORDS_40: 'Too many keywords selected',
      VALIDATOR_MUST_SELECT_NUMBER_OF_PEOPLE:
        'Please specify the number of people in this photo',
      VALIDATOR_MUST_SELECT_DAYTIME: 'Please specify when this photo was taken',
    },
  },
  releasePage: {
    pending: {
      intro: {
        title: 'Release Form',
        text: 'Fill out the form below to give your permission for the displayed photo to be sold commercially on EyeEm Market.',
      },
      modelRelease: {
        modelInfoTitle: "Model's information",
        parentInfoTitle: "Parent's information",
        minorWarning: {
          title: 'Model is under 18',
          text: 'As the model in this photo is a minor, a parent or guardian needs to fill out their details below.',
        },
      },
      propertyRelease: {
        propertyInfoTitle: 'Property information %(tooltip)s',
        ownerInfoTitle: 'Owner information %(tooltip)s',
        addressTitle: 'Address of Property Owner %(tooltip)s',
        sameAddressText:
          'Address of Property Owner is the same as Address of Property',
        propertyCategory: {
          OWN_ARTWORK: 'My artwork/creation',
          ARTWORK: "Someone else's artwork/creation",
          OWN_PROPERTY: 'My home/residence/property',
          PROPERTY: "Someone else's home/residence/property",
          OWN_BRAND: 'My company/brand/logo',
          BRAND: "Someone else's company/brand/logo",
          OTHER: 'Other',
        },
        tooltips: {
          property: {
            title: 'What types of property can I release?',
            text: 'You can sign a release for property that belongs to you and does not contain a visible brand or logo - e.g. your home or artwork. Brands which do not belong to you - e.g. your iPhone or car - require permission from the owner or manufacturer.',
            extraBody: '%(extraBodyLink)s',
            link: {
              label: 'Read more about Property Releases here',
              href: 'https://eyeem.zendesk.com/hc/en-us/articles/202751291',
            },
          },
          owner: {
            title: 'Information about the property owner',
            text: "As the property owner, enter your name and email address. Enter a company name if you are signing a release for your brand or logo - leave blank if you're signing a release for your home or artwork.",
          },
          address: {
            title: 'Where does the property owner live?',
            text: 'If your property and yourself are located at the same address - e.g. your home - you don’t need to provide this extra information. However, if your property is located at a different address, enter your home address here.',
          },
        },
      },
      submit: 'Submit',
      tos: {
        text: 'I agree to  %(releases)s and the %(privacyPolicy)s.',
        consent:
          'I consent to EyeEm using, editing and distributing the displayed photo for editorial and commercial purposes by EyeEm, EyeEm’s partners and licensees. I understand that I will not receive any compensation.',
        model: {
          title: 'Model Releases',
          pdfLink: 'http://dl.eyeem.com/market/model_release.pdf',
        },
        minorModel: {
          title: 'Model Releases for minors',
          pdfLink: 'http://dl.eyeem.com/market/model_release_for_minors.pdf',
        },
        property: {
          title: 'Property Releases',
          pdfLink: 'http://dl.eyeem.com/market/property_release.pdf',
        },
        privacyPolicy: {
          title: 'Privacy Policy',
          pdfLink: 'https://www.eyeem.com/privacy',
        },
      },
      questions: {
        text: 'You can always withdraw your consent with future effect by sending an email to %(supportEmail)s. Please note that it may take up to 90 days to process your request, any previous use of the image will remain valid.',
        marketSupport: {
          label: 'Market Support',
          href: 'https://eyeem.zendesk.com/hc/en-us/',
        },
      },
    },
    settled: {
      title: {
        denied: 'Response successfully sent.',
        accepted: 'Thanks for your help, %(firstName)s!',
      },
      text: 'Interested in selling your own photos? Sign up for EyeEm and start earning money now.',
      button: 'Find out more',
    },
  },
  assetGrid: {
    remove: 'Remove',
  },
  userKeywording: {
    infoBox: {
      content:
        'Fill out caption and keywords like a pro to ensure buyers can discover your work! ',
      tip: 'Tip: ',
      linkText: 'Learn more',
    },
    empty: {
      headline: 'You have no photos to approve.',
      subline:
        'You have no photos that require metadata approval to sell on EyeEm Market and partner sites.',
      button: { href: '/upload/photos', label: 'Upload More Photos' },
    },
    emptySidebar: 'Select an item to edit.',
    form: {
      caption: {
        label: 'Caption',
        placeholder: 'Example: friends using mobile phones while lying on sofa',
        existing: 'Your Caption',
        suggested: 'Suggested Caption',
        info: 'Tip: Tweak the suggestion to make it more findable on market.',
      },
      keywords: {
        input: {
          limitReached:
            'Maximum %(maximum)s allowed. Please remove some keywords',
          label: 'Keywords',
          placeholder: {
            regular: 'Add keywords...',
            more: 'Add more keywords...',
          },
          createTag: 'Create keyword "%(itemName)s"',
          multi: {
            all: 'Keywords on all selected',
            some: 'Keywords on some selected',
          },
        },
        empty: 'Add at least 5 keywords',
        uncontrolled:
          'Some keywords are not recognised. If not due to spelling errors, you can still submit them',
      },
      location: {
        label: 'Location',
        placeholder: 'Add location',
        skipLocationCheckbox: 'Location not found or isn’t relevant',
        hint: 'You can also use the keywords section to add specific location terms.',
      },
      people: {
        label: 'Number of People',
        options: {
          default: 'Please choose',
          noPeople: 'No people',
          onePerson: 'One person',
          twoPeople: 'Two people',
          threePeople: 'Three people',
          mediumGroup: 'Medium group of people',
          bigGroup: 'Large group of people',
        },
      },
      when: { label: 'When', day: 'Day', night: 'Night' },
      where: { label: 'Where', indoors: 'Indoors', outdoors: 'Outdoors' },
      submit: 'Submit',
      uploading: 'Uploading',
    },
  },
  languages: {
    en: 'English',
    de: 'Deutsch',
  },
  countries: {
    AF: 'Afghanistan',
    AX: 'Åland Islands',
    AL: 'Albania',
    DZ: 'Algeria',
    AS: 'American Samoa',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarctica',
    AG: 'Antigua and Barbuda',
    AR: 'Argentina',
    AM: 'Armenia',
    AW: 'Aruba',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgium',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia, Plurinational State of',
    BQ: 'Bonaire, Sint Eustatius and Saba',
    BA: 'Bosnia and Herzegovina',
    BW: 'Botswana',
    BV: 'Bouvet Island',
    BR: 'Brazil',
    IO: 'British Indian Ocean Territory',
    BN: 'Brunei Darussalam',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    CV: 'Cabo Verde',
    KH: 'Cambodia',
    CM: 'Cameroon',
    CA: 'Canada',
    KY: 'Cayman Islands',
    CF: 'Central African Republic',
    TD: 'Chad',
    CL: 'Chile',
    CN: 'China',
    CX: 'Christmas Island',
    CC: 'Cocos (Keeling) Islands',
    CO: 'Colombia',
    KM: 'Comoros',
    CG: 'Congo',
    CD: 'Congo, the Democratic Republic of the',
    CK: 'Cook Islands',
    CR: 'Costa Rica',
    CI: "Côte d'Ivoire",
    HR: 'Croatia',
    CU: 'Cuba',
    CW: 'Curaçao',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    DK: 'Denmark',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    EG: 'Egypt',
    SV: 'El Salvador',
    GQ: 'Equatorial Guinea',
    ER: 'Eritrea',
    EE: 'Estonia',
    ET: 'Ethiopia',
    FK: 'Falkland Islands (Malvinas)',
    FO: 'Faroe Islands',
    FJ: 'Fiji',
    FI: 'Finland',
    FR: 'France',
    GF: 'French Guiana',
    PF: 'French Polynesia',
    TF: 'French Southern Territories',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgia',
    DE: 'Germany',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Greece',
    GL: 'Greenland',
    GD: 'Grenada',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HM: 'Heard Island and McDonald Islands',
    VA: 'Holy See (Vatican City State)',
    HN: 'Honduras',
    HK: 'Hong Kong',
    HU: 'Hungary',
    IS: 'Iceland',
    IN: 'India',
    ID: 'Indonesia',
    IR: 'Iran, Islamic Republic of',
    IQ: 'Iraq',
    IE: 'Ireland',
    IM: 'Isle of Man',
    IL: 'Israel',
    IT: 'Italy',
    JM: 'Jamaica',
    JP: 'Japan',
    JE: 'Jersey',
    JO: 'Jordan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    KP: "Korea, Democratic People's Republic of",
    KR: 'Korea, Republic of',
    KW: 'Kuwait',
    KG: 'Kyrgyzstan',
    LA: "Lao People's Democratic Republic",
    LV: 'Latvia',
    LB: 'Lebanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libya',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MO: 'Macao',
    MK: 'Macedonia, the former Yugoslav Republic of',
    MG: 'Madagascar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malta',
    MH: 'Marshall Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexico',
    FM: 'Micronesia, Federated States of',
    MD: 'Moldova, Republic of',
    MC: 'Monaco',
    MN: 'Mongolia',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MA: 'Morocco',
    MZ: 'Mozambique',
    MM: 'Myanmar',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Netherlands, the',
    NC: 'New Caledonia',
    NZ: 'New Zealand',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    NF: 'Norfolk Island',
    MP: 'Northern Mariana Islands',
    NO: 'Norway',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palestine, State of',
    PA: 'Panama',
    PG: 'Papua New Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippines',
    PN: 'Pitcairn',
    PL: 'Poland',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    RE: 'Réunion',
    RO: 'Romania',
    RU: 'Russian Federation',
    RW: 'Rwanda',
    BL: 'Saint Barthélemy',
    SH: 'Saint Helena, Ascension and Tristan da Cunha',
    KN: 'Saint Kitts and Nevis',
    LC: 'Saint Lucia',
    MF: 'Saint Martin (French part)',
    PM: 'Saint Pierre and Miquelon',
    VC: 'Saint Vincent and the Grenadines',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'Sao Tome and Principe',
    SA: 'Saudi Arabia',
    SN: 'Senegal',
    RS: 'Serbia',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SX: 'Sint Maarten (Dutch part)',
    SK: 'Slovakia',
    SI: 'Slovenia',
    SB: 'Solomon Islands',
    SO: 'Somalia',
    ZA: 'South Africa',
    GS: 'South Georgia and the South Sandwich Islands',
    SS: 'South Sudan',
    ES: 'Spain',
    LK: 'Sri Lanka',
    SD: 'Sudan',
    SR: 'Suriname',
    SJ: 'Svalbard and Jan Mayen',
    SZ: 'Swaziland',
    SE: 'Sweden',
    CH: 'Switzerland',
    SY: 'Syrian Arab Republic',
    TW: 'Taiwan, Republic of China',
    TJ: 'Tajikistan',
    TZ: 'Tanzania, United Republic of',
    TH: 'Thailand',
    TL: 'Timor-Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad and Tobago',
    TN: 'Tunisia',
    TR: 'Turkey',
    TM: 'Turkmenistan',
    TC: 'Turks and Caicos Islands',
    TV: 'Tuvalu',
    UG: 'Uganda',
    UA: 'Ukraine',
    AE: 'United Arab Emirates',
    GB: 'United Kingdom',
    US: 'United States',
    UM: 'United States Minor Outlying Islands',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VE: 'Venezuela, Bolivarian Republic of',
    VN: 'Viet Nam',
    VG: 'Virgin Islands, British',
    VI: 'Virgin Islands, U.S.',
    WF: 'Wallis and Futuna',
    EH: 'Western Sahara',
    YE: 'Yemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
  },
  missionBrief: {
    header: {
      button: 'Signoff brief',
    },
    campaignOverview: {
      headline: 'Campaign overview',
      partnerName: 'Brand name',
      internalTitle: 'Internal campaign title',
      goals: 'Goals of this mission',
      startDate: 'Mission start date',
      endDate: 'Mission end date',
      primaryPurpose: {
        label: 'Primary Purpose',
        options: {
          REACH: 'Reach',
          IMAGE_SOURCING: 'Image Sourcing',
        },
      },
      brandLogo: 'Brand Logo',
      formats: 'JPEG',
      resolution: '300x300px',
    },
    prize: {
      headline: 'Mission prize',
      prizeTitle: 'Prize',
      prizeUrl: 'Prize URL',
      prizeUrlPlaceholder: '+ Add a price link',
      prizePhoto: 'Prize photo',
      formats: 'JPEG, PNG',
      resolution: '1500px on the longest side',
    },
    visualIdentity: {
      headline: 'Visual Identity',
      lightboxId: 'Lightbox URL',
      lightboxIdPlaceholder: 'Add a reference lightbox link',
      visualStyleDescription: 'Visual style description',
      do: 'Please do',
      doPlaceholder: '+ Add a do',
      dont: "Please don't",
      dontPlaceholder: "+ Add a don't",
      brandGuidelinesUrl: 'Brand guidelines documentation',
    },
    location: {
      headline: 'Location',
      isTargeted: {
        label: 'Target area',
        options: {
          false: 'Global',
          true: 'Location based',
        },
        placeholder: '+ Add a location',
      },
    },
    reach: {
      headline: 'Reach',
      tags: {
        title: 'Social hashtags',
        connected: 'Activated',
        disconnect: 'Deactivate',
        connect: 'Activate',
        serviceNames: {
          twitter: '%(icon)sTwitter',
          facebook: '%(icon)sFacebook',
          instagram: '%(icon)sInstagram',
        },
        placeholder: '+ Add hashtag',
      },
      push: {
        headline: 'Push notifications',
        label: 'Enable push notifications',
      },
    },
    notes: {
      headline: 'Additional notes',
    },
    imageDetails: {
      headline: 'Image Details',
      infoBox: {
        headline: 'Need something else?',
        body: 'text text text text text text text',
        button: 'Change requirements',
      },
      securedImages: 'Number of images secured',
      embargoPeriod: 'Embargo period',
      exclusivityOptions: 'Exclusivity options',
      buyoutOptions: 'Buyout options',
      discountAmount: 'Discount amount',
      discountTime: 'Discout time',
    },
    signedModal: {
      headline: 'Mission brief',
      paragraph: 'When you click sign well save that',
      sign: 'Signoff brief',
      cancel: 'Cancel',
    },
  },
  mission: {
    status: {
      IN_PREPARATION: 'In preparation',
      READY_TO_LAUNCH: 'Ready to launch',
      RUNNING: 'Mission running',
      SELECTING_WINNERS: 'Selecting winners',
      COMPLETED: 'Completed',
    },
    submit: 'Submit your photo',
    hostedBy: 'Hosted by',
    mission: 'Mission',
    photos: {
      zero: '%(abbreviatedCount)s Photos',
      one: '%(abbreviatedCount)s Photo',
      other: '%(abbreviatedCount)s Photos',
    },
    illustrations: {
      zero: '%(abbreviatedCount)s Illustrations',
      one: '%(abbreviatedCount)s Illustration',
      other: '%(abbreviatedCount)s Illustrations',
    },
    brief: {
      brief: 'Your Mission',
      recap: 'The Recap',
      whatIsMission: "What's a Mission? %(link)s",
      link: 'Learn more here',
    },
    details: {
      title: 'Prize Details',
      prize: 'The Prize',
      deadline: 'Deadline',
      terms: 'Terms and Conditions',
      marketTipTitle: 'EyeEm Market Tip',
      marketTipBody:
        'Winning photos could be licensed commercially, therefore please ensure your images have been added to Market with the relevant model releases.',
    },
    enter: {
      headline: 'Enter this Mission',
      button: 'Submit your photo',
    },
    expired: {
      winner: 'Winner',
      body: 'Coming soon...',
    },
    completed: {
      grandPrice: 'Grand Prize Winners',
      winner: 'Winners',
      photoCredit: 'By %(name)s',
      runnersUp: 'Finalists',
    },
    sampleImages: {
      headline: 'Visual Inspiration',
    },
    uploadToAlbum: {
      loginHeadline: 'Sign in to add your photos to this mission',
      signupHeadline: 'Join EyeEm to add your work to this mission',
      eventName: 'mission_submit_asset',
    },
    retagging: {
      modal: {
        switch: {
          headline: 'How to contribute',
          paragraph:
            'You can participate in the Mission by uploading from your device, or you can select from your profile.',
          selectButton: 'Select from Profile',
          uploadButton: 'Upload image(s)',
        },
        confirm: {
          message:
            'The selected images above will be submitted to %(missionName)s',
          button: 'Submit images',
          strip: {
            zero: 'Submitting %(boldCount)s images',
            one: 'Submitting %(boldCount)s image',
            other: 'Submitting %(boldCount)s images',
          },
        },
        select: {
          selectedCounter: {
            zero: '%(count)s images selected',
            one: '%(count)s image selected',
            other: '%(count)s images selected',
          },
          cancel: 'Cancel',
          next: 'Next',
        },
      },
    },
  },
  tracking: {
    eventAction: {
      link: 'click_link',
      icon: 'click_icon',
      button: 'click_button',
      asset: 'click_asset',
      passive: 'passive',
      hover: 'hover',
      show: 'show',
      formSubmit: 'form_submit',
    },
    eventAssetType: {
      photo: 'photo',
      illustration: 'illustration',
    },
    eventType: {
      inbound: 'click_inbound',
      outbound: 'click_outbound',
    },
    eventLabel: {
      icon: 'icon',
      selectFromComputer: 'Select from computer',
      uploadAssets: 'upload_assets',
    },
    eventPosition: {
      modalContent: 'modal_content',
      editingTopbar: 'editing_topbar',
      uploadAccess: 'upload_access',
    },
    eventName: {
      ecomModalShow: 'ecom_modal_show',
      ecomModalSignup: 'ecom_modal_signup',
      ecomModalLogin: 'ecom_modal_login',
      ecomDownload: 'ecom_download',
      missionSubmitAssetSuccess: 'mission_submit_asset_success',
      uploadPostAssets: 'upload_post_assets',
      uploadStart: 'upload_start',
    },
  },
  uploadfork: {
    title: 'What are you uploading?',
    subtitle: 'Upload all your best works on EyeEm',
    beta: 'Beta',
    illustrations: {
      title: 'Illustrations',
      subtitle: 'Your scanned artwork or digitally produced illustrations',
    },
    photos: {
      title: 'Photos',
      subtitle: 'Your digital, analog or mobile camera photos',
    },
  },
  uploader: {
    missionSubmissionFailedModal: {
      duplicate: {
        title: 'Images are already submitted!',
        body: 'These images are already part of the mission.',
      },
      generic: {
        title: 'Oops! Something went wrong.',
        body: 'Some images were skipped. Please, try again! We had to skip:',
      },
      submissionTypeNotSupported: {
        title: 'Oops! Something went wrong.',
        body: 'Looks like you’ve uploaded photos. You can only upload illustrations to this mission. We had to skip:',
      },
      cta: 'Okay',
    },
    illustrations: {
      header: 'Upload your illustrations',

      specifications: {
        resolution: 'Minimum resolution: 4MP',
        size: 'Maximum file size: 45MB',
        format: 'Must be JPG files',
        license: 'Must be suitable for market (commercial)',
      },
      addMore: 'Add more illustrations',
      unsupported: {
        title: 'Some files were skipped',
        body: 'Make sure that your files are JPGs and meet the minimum resolution (4MP) and maximum file size (45MB) requirements. We had to skip:',
        cta: 'Okay',
        reason: {
          fileTooBig: 'File too big',
          wrongFileFormat: 'File is not in jpeg format',
          resolutionTooSmall: 'The resolution is too small',
          tooManyFiles: 'Too many files',
        },
        more: '+ %(count)s more',
      },
      emptySidebar:
        'You can use SHIFT or command/CTRL to edit multiple photos.',
      form: {
        categories: {
          title: 'Categories',
          subtitle: '(pick 1-2)',
          dropdownPlaceholder: 'Choose categories',
          error: 'Pick at least one category',
        },
        styles: {
          title: 'Styles',
          subtitle: '(pick 1-2)',
          dropdownPlaceholder: 'Choose styles',
          error: 'Pick at least one style',
        },
        caption: {
          label: 'Caption',
          placeholder:
            'Example: seamless pattern of whales with blue and pink floral background elements',
          multiplePlaceholder: 'Replacing multiple captions',
          info: 'Tip: Tweak the suggestion to make it more findable on market.',
        },
        missions: {
          title: 'Missions',
          optional: 'optional',
          subtitle: 'Only submit illustrations that fit the mission brief.',
          linkText: 'Explore briefs',
          tooltip:
            'Missions are competitions to help you to gain exposure and earn prices.',
          hostedBy: 'Hosted by',
          readBrief: 'Read brief',
          dropdownPlaceholder: 'Choose a mission',
          emptyDropdown: 'No active missions. Stay tuned!',
        },
      },
    },
  },
  assetType: {
    illustrations: {
      zero: 'illustration',
      one: 'illustration',
      other: 'illustrations',
    },
    photos: {
      zero: 'photo',
      one: 'photo',
      other: 'photos',
    },
  },
  verifyEmail: {
    success: {
      title: 'Email successfully verified!',
      link: 'Click here to login',
    },
  },
  copyrightModal: {
    title: 'Ownership',
    paragraph:
      'By adding an image to EyeEm Market, you confirm that you created the image.',
    link: {
      label: 'Learn more',
      href: 'https://www.eyeem.com/blog/what-you-need-to-know-about-eyeem-market-copyright-restrictions',
      eventName: 'copyright_modal_learn_more',
    },
    eventPosition: 'pv_copyright_modal',
    buttons: {
      cancel: {
        label: 'Cancel',
        eventName: 'copyright_modal_cancel',
      },
      confirm: {
        label: 'Next',
        eventName: 'copyright_modal_confirm',
      },
    },
    checkbox: 'Yes, I created the image.',
  },
};
